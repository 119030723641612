export const FirstASpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    }
  ],
};

export const FirstAGateway = {
  additionalElementTop: {
    space: 12,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    }
  ],
};

export const FirstAGateTilting = {
  additionalElementTop: {
    space: 12,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    }
  ],
};

export const FirstAGateSelfSupporting = {
  additionalElementTop: {
    space: 13,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    }
  ],
};
