import React from 'react';
import PropTypes from 'prop-types';
import ProjectVisualisationBody from './ProjectVisualisationBody';
import './ProjectVisualisationGateTilting.css';

const ProjectVisualisationGateTilting = ({project, projectVisualisation, containerStyle}) => {
  return (
    <div className={'projectVisualisation projectVisualisationGateTilting'} style={containerStyle} data-scale={projectVisualisation.scale}>
      <div className={'projectVisualisationGateTilting__spanLeftTop'}></div>
      <div className={'projectVisualisationGateTilting__spanLeft'}></div>
      <div className={'projectVisualisationGateTilting__spanLeftBottom'}></div>
      <div className={'projectVisualisationBody projectVisualisationBody__gateTiltingTop'}></div>
      <ProjectVisualisationBody project={project} projectVisualisation={projectVisualisation}/>
      <div className={'projectVisualisationBody projectVisualisationBody__gateTiltingBottom'}></div>
      <div className={'projectVisualisationGateTilting__spanMiddleTop'}></div>
      <div className={'projectVisualisationGateTilting__spanMiddle'}></div>
      <div className={'projectVisualisationGateTilting__spanMiddleBottom'}></div>
      <div className={'projectVisualisationGateTilting__spanRightTop'}></div>
      <div className={'projectVisualisationGateTilting__spanRight'}></div>
      <div className={'projectVisualisationGateTilting__spanRightBottom'}></div>
    </div>
  );
};

ProjectVisualisationGateTilting.propTypes = {
  project: PropTypes.object.isRequired,
  projectVisualisation: PropTypes.object.isRequired,
  containerStyle: PropTypes.object.isRequired,
};

export default ProjectVisualisationGateTilting;
