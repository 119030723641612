import {connect} from 'react-redux';
import {addProject, setAlert, setCurrentProjectId, setExampleModel, setMenu, updateProject} from '../../actions';
import ProjectType from '../../components/Menu/ProjectType';

const mapStateToProps = (state) => ({
  menu: state.app.menu,
  projects: state.app.projects,
  currentProjectId: state.app.currentProjectId,
  canStartDesigning: state.app.canStartDesigning,
  exampleModel: state.app.exampleModel,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentProjectId: (projectId) => dispatch(setCurrentProjectId(projectId)),
  updateProject: (project) => dispatch(updateProject(project)),
  addProject: (project) => dispatch(addProject(project)),
  setAlert: (alert) => dispatch(setAlert(alert)),
  setExampleModel: (exampleModel) => dispatch(setExampleModel(exampleModel)),
  setMenu: (menu) => dispatch(setMenu(menu)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectType);
