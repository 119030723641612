import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import appReducer from './reducers';
import * as Sentry from '@sentry/browser';
import App from './components/App';
import {composeWithDevTools} from 'redux-devtools-extension';

export const store = createStore(
  appReducer,
  composeWithDevTools(),
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: 'https://146cf5245e1748949fd422d553b114eb@o380431.ingest.sentry.io/5221837'});
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
