export const ThirdCSpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const ThirdCGateway = {
  additionalElementTop: {
    space: 70,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    }
  ],
};

export const ThirdCGateTilting = {
  additionalElementTop: {
    space: 70,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    },
    {
      size: 80,
      space: 70,
    },
    {
      size: 20,
      space: 70,
    }
  ],
};

export const ThirdCGateSelfSupporting = {
  additionalElementTop: {
    space: 70,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 20,
      space: 64,
    },
    {
      size: 80,
      space: 64,
    },
    {
      size: 20,
      space: 64,
    },
    {
      size: 80,
      space: 64,
    },
    {
      size: 20,
      space: 64,
    },
    {
      size: 80,
      space: 64,
    },
    {
      size: 20,
      space: 64,
    },
    {
      size: 80,
      space: 65,
    },
    {
      size: 20,
      space: 65,
    },
    {
      size: 80,
      space: 65,
    },
    {
      size: 20,
      space: 65,
    },
    {
      size: 80,
      space: 65,
    },
    {
      size: 20,
      space: 65,
    },
    {
      size: 80,
      space: 65,
    },
    {
      size: 20,
      space: 65,
    }
  ],
};
