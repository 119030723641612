import {SPAN_MIN_SPACE} from './components/Rod/Rods';

const changeRodsForSpanRequirements = (project) => {
  const lastIndex = project.rods.length - 1;

  const newProject = {...project};

  newProject.rods = project.rods.map((rod, index) => (lastIndex === index ? {
      ...rod,
      disabled: true,
      space: 0,
    } : {
      ...rod,
      space: rod.space >= SPAN_MIN_SPACE ? rod.space : project.defaultSpace,
    }
  ));

  return newProject;
};

export default changeRodsForSpanRequirements;
