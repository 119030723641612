import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ModalActiveSlideContainer,
  ModalBody, ModalBtn,
  ModalHeader,
  ModalSliderBtn,
  ModalSliderContainer,
  ModalTitle
} from './MachineModal';
import { MAILBOX_MODAL_IS_SHOWING } from '../actions';

const Img = styled.img`
  width: 600px;
  height: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`;


const slides = [
  {
    image: "/img/mailbox/mailbox1.png",
    text: " skrzynkę Invisible można zamontować tylko do profilu o wysokości 80 mm. " +
      "Dodatkowo profil ten musi sąsiadować z innym profilem o wysokości 80 mm, a odstęp miedzy nimi musi kształtować " +
      "się w przedziale od 10 mm do 20 mm. Jeśli powyższe warunki zostają spełnione program pozwoli wybrać profil, " +
      "w którym zamierzasz umieścić skrzynkę Invisible.",
  },
  {
    image: "/img/mailbox/mailbox2.png",
    text: "W ostatnim kroku musisz określić położenie skrzynki na profilu wybierając kolejno opcję położenia: " +
      "centralnego, bliżej krawędzi lewej lub prawej. Pamiętaj również, że w zależności od wybranej kategorii " +
      "(furtka lub przęsło) szerokość twojego projektu ulega pomniejszeniu ze względu na typ zawiasu lub obejmy. " +
      "Dla pewności zajrzyj do katalogu technicznego modelu Diverso. W tabeli Informacje, a następnie pozycji " +
      "\"szerokość\" znajdziesz wszelkie informacje na temat wymiarów o jakie pomniejszana jest szerokość " +
      "przęsła i furtki."
  },
];

const MailboxModal = () => {
  const dispatch = useDispatch();

  const mailboxModalIsShowing = useSelector(state => state.app.mailboxModalIsShowing);

  const [activeSlide, setActiveSlide] = useState(slides[0]);

  const hideModal = () => {
    dispatch({ type: MAILBOX_MODAL_IS_SHOWING, mailboxModalIsShowing: false })
  };

  const goToPrevSlide = () => {
    if (activeSlide === slides[0]) {
      setActiveSlide(slides[slides.length - 1]);
    } else {
      const currentSlideIndex = slides.findIndex((slide) => slide === activeSlide);
      setActiveSlide(slides[currentSlideIndex - 1]);
    }
  };

  const goToNextSlide = () => {
    if (activeSlide === slides[slides.length - 1]) {
      setActiveSlide(slides[0]);
    } else {
      const currentSlideIndex = slides.findIndex((slide) => slide === activeSlide);
      setActiveSlide(slides[currentSlideIndex + 1]);
    }
  };

  const showActiveSlide = () => {
    return (
      <ModalActiveSlideContainer>
        <Img alt="" src={activeSlide.image}/>
        {activeSlide === slides[0] && <b>Pamiętaj, że</b>}
        {activeSlide.text}
      </ModalActiveSlideContainer>
    )
  };

  if (mailboxModalIsShowing) {
    return (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <ModalHeader>
              <ModalTitle>UWAGA</ModalTitle>
            </ModalHeader>
            <ModalBody className="modal-body">
              <ModalSliderContainer>
                <ModalSliderBtn className="button btn-secondary" onClick={goToPrevSlide}>
                  <FontAwesomeIcon icon={faAngleLeft} style={{ color: '#ffffff' }}/>
                </ModalSliderBtn>
                {showActiveSlide()}
                <ModalSliderBtn className="button btn-secondary" onClick={goToNextSlide}>
                  <FontAwesomeIcon icon={faAngleRight} style={{ color: '#ffffff' }}/>
                </ModalSliderBtn>
              </ModalSliderContainer>
              <div>
                <ModalBtn
                  className="btn btn-success"
                  type="button"
                  style={{ marginTop: '5rem' }}
                  onClick={hideModal}
                >
                  <span>Rozumiem</span>
                </ModalBtn>
              </div>
            </ModalBody>
          </div>
        </div>
      </div>
    )
  }
  return null;
};

export default MailboxModal;
