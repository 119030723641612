export const FourthBSpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const FourthBGateway = {
  additionalElementTop: {
    space: 10,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    }
  ],
};

export const FourthBGateTilting = {
  additionalElementTop: {
    space: 10,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 10,
    },
    {
      size: 20,
      space: 10,
    },
    {
      size: 80,
      space: 10,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    }
  ],
};

export const FourthBGateSelfSupporting = {
  additionalElementTop: {
    space: 10,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    }
  ],
};
