import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid_v4 } from 'uuid';
import {projectBase} from './Menu/ProjectType';
import { getExampleModel } from '../exampleModel/ExampleModel';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: lighter;
  margin: 2rem 3rem 0 1rem;
`;

const ModalBody = styled.div`
  padding: 1rem 3rem;
`;

const BtnContainer = styled.div`
  display: flex;
  & > button {
    margin-right: 1rem;
  }
`;

const Btn = styled.button`
  padding: 0.5rem 2rem;
  border-radius: 22px;
  
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

const ShouldLoadExampleModelAlert = ({exampleModel, addProject, setCurrentProjectId, setExampleModel}) => {
  const numberOfProjectsPerType = useSelector(state => state.app.numberOfProjectsPerType);

  if (!exampleModel.showAlert) {
    return null;
  }

  const onYes = () => {
    const model = getExampleModel(exampleModel.used);
    const project = projectBase(exampleModel.nextFenceType, numberOfProjectsPerType);

    project.totalSpace = 0;
    project.defaultSpace = model.defaultSpace;
    project.height = model.height;
    project.heightExpected = model.height;
    project.width = model.width;
    project.additionalElementTop = model[exampleModel.nextFenceType].additionalElementTop;
    project.rods = model[exampleModel.nextFenceType].rods;
    project.additionalElementBottom = model[exampleModel.nextFenceType].additionalElementBottom;

    project.rods = project.rods.map((rod) => ({
      ...rod,
      id: uuid_v4(),
    }));

    addProject(project);
    setCurrentProjectId(project.id);

    setExampleModel({...exampleModel, showAlert: false});
  };

  const onNo = () => {
    setExampleModel({...exampleModel, showAlert: false});

    const newProject = projectBase(exampleModel.nextFenceType, numberOfProjectsPerType);
    addProject(newProject);
  };

  return (
    <div id={'shouldLoadExampleModelAlert'} className="modal" tabIndex="-1" role="dialog" style={{display: 'block'}}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <ModalHeader>
            <Title>Zmieniasz typ projektu</Title>
          </ModalHeader>
          <ModalBody className="modal-body">
            <p>Czy chcesz zachować wybrany model przykładowy?</p>
            <BtnContainer>
            <Btn type={'button'} className={'btn btn-success'} onClick={onYes}>Tak</Btn>
            <Btn type={'button'} className={'btn btn-danger'} onClick={onNo}>Nie</Btn>
            </BtnContainer>
          </ModalBody>
        </div>
      </div>
    </div>
  );
};

ShouldLoadExampleModelAlert.propTypes = {
  exampleModel: PropTypes.object.isRequired,
  addProject: PropTypes.func.isRequired,
  setCurrentProjectId: PropTypes.func.isRequired,
  setExampleModel: PropTypes.func.isRequired,
};

export default ShouldLoadExampleModelAlert;
