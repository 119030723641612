import React from 'react';
import ReactDOMServer from 'react-dom/server';
import wrapWithinHtml from './wrapWithinHtml';
import Projects from './Projects';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {SET_PDF_SHOW_MODAL} from '../../actions';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';

const Button = styled.button`
  background: #575b5d;
  color: #eceded;
  border-radius: 40px;
  padding: 0.5rem 2.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  &:hover {
    background: #eceded;
    color: #575b5d;
  }
`;

export const generatePdf = (html) => fetch('https://www.api.pdf.konsport.com', {
  method: 'POST',
  headers: {
    'Content-Type': 'text/html; charset=utf-8',
    'X-Project-Name': 'DIVERSO',
    'X-Pdf-Name': `diverso`,
    'X-Include-Footer': '1',
    'X-Include-Page-Number': '1',
  },
  body: html,
});


export const generateHtml = (projects, pdfOptions) => {
  const htmlProjects = ReactDOMServer.renderToStaticMarkup(<Projects projects={projects} pdfOptions={pdfOptions}/>);
  const html = wrapWithinHtml(htmlProjects);
  // window.open().document.write(html);

  return html;
};

export const PDF_GENERATE_OPTION_ONE = 'one';
export const PDF_GENERATE_OPTION_ALL = 'all';

const PdfBtn = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <Button
        className={'projectActionBtn btn btn-icon-left'}
        type={'button'}
        data-sentry="generate-pdf"
        onClick={() => dispatch({type: SET_PDF_SHOW_MODAL, showModal: true})}
      >
        <FontAwesomeIcon icon={faFilePdf}/>
        <span>Generuj PDF</span>
      </Button>
    </div>
  );
};

export default PdfBtn;
