export const ThirdASpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 69,
    },
    {
      size: 20,
      space: 69,
    },
    {
      size: 80,
      space: 69,
    },
    {
      size: 20,
      space: 69,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const ThirdAGateway = {
  additionalElementTop: {
    space: 68,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 69,
    },
    {
      size: 20,
      space: 69,
    },
    {
      size: 80,
      space: 69,
    },
    {
      size: 20,
      space: 69,
    }
  ],
};

export const ThirdAGateTilting = {
  additionalElementTop: {
    space: 68,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 68,
    },
    {
      size: 20,
      space: 68,
    },
    {
      size: 80,
      space: 69,
    },
    {
      size: 20,
      space: 69,
    },
    {
      size: 80,
      space: 69,
    },
    {
      size: 20,
      space: 69,
    }
  ],
};

export const ThirdAGateSelfSupporting = {
  additionalElementTop: {
    space: 68,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 20,
      space: 61,
    },
    {
      size: 80,
      space: 61,
    },
    {
      size: 20,
      space: 61,
    },
    {
      size: 80,
      space: 61,
    },
    {
      size: 20,
      space: 61,
    },
    {
      size: 80,
      space: 61,
    },
    {
      size: 20,
      space: 61,
    },
    {
      size: 80,
      space: 61,
    },
    {
      size: 20,
      space: 61,
    },
    {
      size: 80,
      space: 61,
    },
    {
      size: 20,
      space: 60,
    }
  ],
};
