import React from 'react';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { v4 as uuid_v4 } from 'uuid';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import {FENCE_TYPE_GATE_SELF_SUPPORTING, FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY} from 'fenceTypesConstants';
import ProjectSelect from './ProjectSelect';
import {REMOVE_PROJECT, SET_CURRENT_PROJECT_ID} from '../../actions';
import './ProjectType.css';
import Tooltip from './Tooltip';
import { STEP_ONE_PROJECT_SELECT } from '../../ProjectConstants';
import WidthModal from '../WidthModal';

export const gatewayDefaultAdditionalElementTopSize = 80;
export const gateTiltingDefaultAdditionalElementTopSize = 80;
export const gateSelfSupportingDefaultAdditionalElementTopSize = 80;

export const gatewayDefaultAdditionalElementBottom = {
  space: null,
  size: 80,
};

export const gateTiltingDefaultAdditionalElementBottom = {
  space: null,
  size: 80,
};

export const gateSelfSupportingDefaultAdditionalElementBottom = {
  space: null,
  size: 162,
};

// TODO keep this value in the state
export const areAllProjectFenceTypesExists = (numberOfProjectsPerType) => Object.keys(numberOfProjectsPerType).filter((type) => numberOfProjectsPerType[type] > 0).length === Object.keys(numberOfProjectsPerType).length;

export const defaultSpace = 10;

export const projectBase = (fenceType, numberOfProjectsPerType, overwriteValues = null) => {
  const indexForHuman = numberOfProjectsPerType[fenceType] + 1;

  let project = {
    id: uuid_v4(),
    type: fenceType,
    indexForHuman,
    width: null,
    height: 0,
    heightExpected: null,
    totalSpace: 0,
    defaultSpace,
    additionalElementTop: null,
    additionalElementBottom: null,
    rods: [],
  };

  switch (fenceType) {
    case FENCE_TYPE_GATEWAY:
      project = {
        ...project,
        additionalElementTop: {
          space: defaultSpace,
          size: gatewayDefaultAdditionalElementTopSize,
        },
        additionalElementBottom: gatewayDefaultAdditionalElementBottom,
      };
      break;
    case FENCE_TYPE_GATE_TILTING:
      project = {
        ...project,
        additionalElementTop: {
          space: defaultSpace,
          size: gateTiltingDefaultAdditionalElementTopSize,
        },
        additionalElementBottom: gateTiltingDefaultAdditionalElementBottom,
      };
      break;
    case FENCE_TYPE_GATE_SELF_SUPPORTING:
      project = {
        ...project,
        additionalElementTop: {
          space: defaultSpace,
          size: gateSelfSupportingDefaultAdditionalElementTopSize,
        },
        additionalElementBottom: gateSelfSupportingDefaultAdditionalElementBottom,
      };
      break;
    default:
    // Do nothing
  }

  return {
    ...project,
    ...overwriteValues,
  };
};

const ProjectType = (
  {
    projects,
    project,
    addProject,
    setExampleModel,
    setMenu,
    setOpenTooltip,
  }
) => {
  const dispatch = useDispatch();

  const prevProjectId = useSelector(state => state.app.prevProjectId);

  const remove = () => {
    dispatch({type: REMOVE_PROJECT, projectId: project.id});

    const prevProjectExist = projects.some((p) => p.id === prevProjectId);
    if (prevProjectExist) {
      dispatch({type: SET_CURRENT_PROJECT_ID, projectId: prevProjectId});

      return;
    }

    const nextProjectsWithSameType = projects.filter((p) => p.type === project.type && p.id !== project.id);
    if (nextProjectsWithSameType.length > 0) {
      dispatch({type: SET_CURRENT_PROJECT_ID, projectId: nextProjectsWithSameType[0].id});

      return;
    }

    const nextProject = projects.filter((p) => p.id !== project.id);
    if (nextProject.length) {
      dispatch({type: SET_CURRENT_PROJECT_ID, projectId: nextProject[0].id});
    }
  };

  if (!project) {
    setOpenTooltip(true);
  } else {
    setOpenTooltip(false);
  }

  return (
    <ProjectTypeContainer id={'projectTypeContainer'}>
      <ProjectSelect
        project={project}
        addProject={addProject}
        setExampleModel={setExampleModel}
        setMenu={setMenu}
        setOpenTooltip={setOpenTooltip}
      />
      {projects.length > 0 && project &&
      <RemoveProjectBtn type={'button'} className={'btn'} id={'removeProjectBtn'} onClick={remove}>
        <FontAwesomeIcon icon={faTrashAlt}/>
      </RemoveProjectBtn>
      }
      {!project && <Tooltip situation={STEP_ONE_PROJECT_SELECT} />}
      {project && <WidthModal />}
    </ProjectTypeContainer>
  );
};

const ProjectTypeContainer = styled.div`
  position: relative;
`;

const RemoveProjectBtn = styled.button`
    margin: 2rem;
    height: 30px;
    
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
`;

export default ProjectType;
