import React, { useState } from 'react';
import { v4 as uuid_v4 } from 'uuid';
import { exampleModelsArray } from 'exampleModel/ExampleModel';
import { findProjectIdInUrl } from '../LoadProjectFromUrl/LoadProjectFromUrl';
import { useDispatch, useSelector } from 'react-redux';
import {
  EXAMPLE_PROJECT_WAS_CHOSEN,
  SET_CHOSEN_OPTION,
} from 'actions';
import { projectBase } from '../Menu/ProjectType';
import { fencesTypesOnlyKeys } from 'fenceTypesConstants';
import styled from 'styled-components'
import { DIY_MODEL, PREDEFINED_MODEL } from 'ProjectConstants';
import logo from 'assets/logo.svg';

const Container = styled.div`
  position: fixed;
  height: 100vh;
  min-height: 700px;
  background: #2d3234;
  overflow: auto;
`;

const HeaderImg = styled.img`
  width: 200px;
  margin: 4rem 4rem 2rem;
`;

const Title = styled.h1`
  color: #ffffff;
  margin: 2rem 4rem 4rem;
  font-weight: lighter;
`;

const ModelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 4rem;
  & > div {
    margin-right: 2rem;
  }
`;

export const SingleModelContainer = styled.div`
  background: #ffffff;
  padding: 1.5rem;
  border: 2px solid #ffffff;
  
  &:hover {
    border: 2px solid #ff0000;
    & > div > h5 {
      color: #ff0000;
    }
    & > div > .diyBtn {
     color: #ffffff;
     background: #ff0000;
     border:none;
     outline: none;
     box-shadow: none;
     pointer-events: auto;
    }
  }
  
  &:focus {
    box-shadow: 0 0 10px 3px #ff0000;
    outline: none;
    border: 2px solid #ff0000;
  }
  
  &:active {
    box-shadow: 0 0 10px 3px #ff0000;
    outline: none;
    border: 2px solid #ff0000;
  }
  
  &.highlighted {
    border: 2px solid #ff0000;
    box-shadow: 0 0 10px 3px #ff0000;
    outline: none;
  }
  
  &.highlighted > div > .selectOptionBtn {
    color: #ffffff;
    background: #ff0000;
    border:none;
    outline: none;
    box-shadow: none;
    pointer-events: auto;
  }
  
  &.highlighted > div > .selected {
    background: #000000;
    color: #ffffff;
    border:none;
    outline: none;
    box-shadow: none;
  }
`;

export const SingleModelTitle = styled.div`
  margin-bottom: 1.5rem;
  & > h5 {
    font-weight: bold;
  }
`;

export const ModelImgContainer = styled.div`
  display: flex;
  justify-items: center;
`;

export const ModelImg = styled.img`
  ${props => props.diy ? 'width: 131px' : 'width: 150px'};
  height: auto;
  margin-bottom: 1rem;
  ${props => props.diy && 'display: block; margin-left: auto; margin-right: auto;'}
`;

export const ModelSpanContainer = styled.div`
  font-size: 0.9rem;
`;

const DiySpanContainer = styled.div`
  font-size: 0.9rem;
  margin-bottom: 2rem;
`;

export const ParameterBtnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  
  & > button {
    margin-bottom: 0.25rem;
  }
`;

export const ParameterBtn = styled.button`
  color: #ffffff;
  background: #575b5d;
  border: none;
  border-radius: 50px;
  padding: 0.25rem 0;
  
  &:hover {
    background: #000000;
    color: #ffffff;
  }
  
  &:focus {
    background: #000000;
    color: #ffffff;
    border:none;
    outline: none;
    box-shadow: none;
  }
  
  &:active {
    background: #000000;
    color: #ffffff;
    border:none;
    outline: none;
    box-shadow: none;
  }
`;

export const SelectBtnContainer = styled.div`
  margin: 0.5rem 0;
`;

export const SelectBtn = styled.button`
  pointer-events: ${props => props.diy ? 'auto' : 'none'};
  background: #f2f2f2;
  border: none;
  border-radius: 50px;
  color: #bfbfbf;
  padding: 0.25rem 3rem;
  
  &:hover {
  background: ${props => props.diy ? '#ff0000' : '#f2f2f2'};
  color:  ${props => props.diy ? '#ffffff' : '#bfbfbf'};
  }
  
  &:active {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

const Footer = styled.div`
  color: #8e9192;
  font-size: 0.9rem;
  margin: 4rem;
`;

const CopyP = styled.p`
  margin-top: 2rem;
`;

const GettingStarted = () => {
  const dispatch = useDispatch();

  const projectId = findProjectIdInUrl();

  // const [, setHighlightedModel] = useState(null);
  const [ height, ] = useState(null);
  const [ width, ] = useState(null);

  const exampleModel = useSelector(state => state.app.exampleModel);
  const numberOfProjectsPerType = useSelector(state => state.app.numberOfProjectsPerType);

  if (projectId) {
    return null;
  }

  /*const chooseModelParameters = (index, height=null, width=null) => {
    setHighlightedModel(index);
    setHeight(height);
    setWidth(width);
  };*/

  const chooseModel = (index) => {
    if (index === DIY_MODEL) {
      dispatch({ type: SET_CHOSEN_OPTION, chosenOption: DIY_MODEL });
    } else if (index < exampleModelsArray.length) {
      const selectedModel = exampleModelsArray[index].find((m) => {
        return height === m.height && width === m.width
      });
      if (selectedModel.height === height && selectedModel.width === width) {
        const model = selectedModel;

        let lastProject;
        const projectsToAdd = [];
        // eslint-disable-next-line array-callback-return
        fencesTypesOnlyKeys.map((key) => {
          const projectData = model[key];
          const project = projectBase(key, numberOfProjectsPerType, {
            defaultSpace: model.defaultSpace,
            height: model.height,
            heightExpected: model.height,
            width: model.width,
            additionalElementTop: projectData.additionalElementTop,
            rods: projectData.rods,
            additionalElementBottom: projectData.additionalElementBottom,
          });

          project.rods = project.rods.map((rod) => ({
            ...rod,
            id: uuid_v4(),
          }));

          lastProject = project;
          projectsToAdd.push(project);
        });

        projectsToAdd.forEach((project) =>
        dispatch({
          type: EXAMPLE_PROJECT_WAS_CHOSEN,
          project: project,
          currentProjectId: lastProject.id,
          exampleModel: { ...exampleModel, used: model},
          chosenOption: PREDEFINED_MODEL,
        }))
      } else {
        dispatch({ type: SET_CHOSEN_OPTION, chosenOption: null });
      }
    }
  };

  const DiyModel = () => {
    return (
      <SingleModelContainer className="diyModel">
        <SingleModelTitle>
          <h5>Zaprojektuj <br/>własny wzór</h5>
          <DiySpanContainer>
            <span>Stwórz własny<br/> unikalny projekt<br/> ogrodzenia.</span>
          </DiySpanContainer>
        </SingleModelTitle>
        <ModelImgContainer>
          <ModelImg src="img/predefinedModel/diy.svg" alt="" diy/>
        </ModelImgContainer>
        <SelectBtnContainer>
          <SelectBtn diy className="btn" type="button" onClick={() => chooseModel(DIY_MODEL)}>Wybierz</SelectBtn>
        </SelectBtnContainer>
      </SingleModelContainer>
    )
  };

  return (
    <Container id={'GettingStartedModal'} className="container-fluid" tabIndex="-1" role="dialog"
               style={{ display: 'block' }}>
      <div className="row">
        <div className="col">
          <div>
            <HeaderImg src={logo} alt=""/>
          </div>
          <div>
            <Title>Wybierz wzór ogrodzenia</Title>
          </div>
          <ModelsContainer>
            {/*commented for now*/}
            {/*<Models*/}
            {/*highlightedModel={highlightedModel}*/}
            {/*height={height}*/}
            {/*width={width}*/}
            {/*chooseModelParameters={chooseModelParameters}*/}
            {/*chooseModel={chooseModel}*/}
            {/*/>*/}
            <DiyModel/>
          </ModelsContainer>
          <Footer>
            <span>
              Jana Kilińskiego 75
              <br/>
              95-083 Kazimierz
              <br/>
              +48 43 677 50 63
              <br/>
              biuro@konsport.com.pl
            </span>
            <CopyP>
              &copy; Konsport
            </CopyP>
          </Footer>
        </div>
      </div>
    </Container>
  );
};

export default GettingStarted;
