import React from 'react';

const Signature = () => {
  const style = {
    width: '200px',
    borderBottom: '2px solid #000',
    position: 'absolute',
    right: '30px',
    top: '1300px',
  };

  return (
    <div style={style}>
      <p style={{marginBottom: '3rem'}}>Podpis:</p>
    </div>
  );
};

export default Signature;
