import {connect} from 'react-redux';
import {setAlert, updateProject} from '../../actions';
import RodsCollection from '../../components/Rod/RodsCollection';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  updateProject: (project) => dispatch(updateProject(project)),
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RodsCollection);
