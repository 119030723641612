import React from 'react';
import PropTypes from 'prop-types';
import {FENCE_TYPE_SPAN} from 'fenceTypesConstants';
import styled from 'styled-components';

const AdditionalElementBottomDiv = styled.div`
  background: #bfbfbf;
  border-radius: 50px;
  color: #000000;
  width: 100%;
  padding: 1rem 2rem 0.5rem 2rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const AdditionalElementBottom = ({project}) => {
  if (project.type === FENCE_TYPE_SPAN) {
    return null;
  }

  return (
    <AdditionalElementBottomDiv id={'additionalElementBottom'} className={'rod'}>
      <span>Profil <b>{project.additionalElementBottom.size}</b> mm (RAMA DOLNA)</span>
      <span>Element wymagany</span>
    </AdditionalElementBottomDiv>
  );
};

AdditionalElementBottom.propTypes = {
  project: PropTypes.object.isRequired,
};

export default AdditionalElementBottom;
