import React from 'react';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { v4 as uuid_v4 } from 'uuid';
import { FENCE_TYPE_SPAN } from 'fenceTypesConstants';
import {animateScroll} from 'react-scroll';
import styled from 'styled-components';
import Tooltip from '../Menu/Tooltip';
import {
  DIY_MODEL,
  STEP_THREE_ROD_COLLECTION
} from '../../ProjectConstants';
import { useSelector } from 'react-redux';

export const rodsSizes = [20, 40, 80, 100];

const Container = styled.section`
   position: fixed;
   left: 450px;
   bottom: 0;
   padding: 1rem 2rem;
   background: #000000;
   color: #ffffff;
   width: calc(100vw - 450px);
   flex-wrap: wrap;
   box-shadow: 0 0 10px 1px #000000;
  
  & > div {
  display: flex;
  align-items: center;
 }
`;

const AddSectionP = styled.p`
  font-weight: bold;
  margin-left: 2rem;
  margin-right: 3rem;
  margin-top: 0.75rem;
`;

const Btn = styled.button`
  border-radius: 50px;
  padding: 0.5rem 1.5rem;
  margin-right: 2rem;
`;

const TooltipContainer = styled.div`
  position: relative;
`;

const RodsCollection = ({project, updateProject, setAlert, setOpenTooltip }) => {
  const chosenOption = useSelector(state => state.app.chosenOption) || null;

  const insertRod = (rods, rod) => {
      const newRods = rods.slice();
      newRods.push(rod);

      return newRods;
    };

    const onClick = (size) => {
      if (!size) {
        throw new Error();
      }

      if (project.height + size + project.defaultSpace > project.heightExpected) {
        setAlert({
          title: (<span>
            Nie możesz dodać kształtownika.<br/>
            Pozostało Ci tylko {project.heightExpected - project.height} mm.<br/>
            Usunęliśmy go.
          </span>)
        });

        return;
      }

      let space = project.defaultSpace;
      if (!project.rods.length && project.type === FENCE_TYPE_SPAN) {
        space = 0;
      }

      let newRods;

        newRods = insertRod(project.rods, {
          id: uuid_v4(),
          size,
          space,
        });

      updateProject({...project, rods: newRods});

      const scroll = animateScroll;
      scroll.scrollToBottom();
    };

    const Rods = () => rodsSizes.map((size, index) => (
      <Btn
        key={index}
        type={'button'}
        className={'btn btn-icon-left btn-danger'}
        data-sentry={`add-rod-${size}`}
        onClick={() => onClick(size)}>
        <FontAwesomeIcon icon={faPlus}/>
        <b>{size}</b> mm
      </Btn>
    ));

    if (chosenOption === DIY_MODEL && !project.rods) {
      setOpenTooltip(true);
    } else {
      setOpenTooltip(false);
    }

    return (
      <Container id={'rodsCollection'}>
        <div>
          <AddSectionP>Dodaj<br/>kształtownik:</AddSectionP>
          <Rods/>
        </div>
        {(chosenOption === DIY_MODEL && project.rods.length === 0 && project.heightExpected > 0 && project.width) && (<TooltipContainer><Tooltip situation={STEP_THREE_ROD_COLLECTION}/></TooltipContainer>)}
      </Container>
    );
  }
;

RodsCollection.propTypes = {
  project: PropTypes.object,
  updateProject: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default RodsCollection;
