export const DIY_MODEL = 'DIY_MODEL';
export const PREDEFINED_MODEL = 'PREDEFINED_MODEL';

export const STEP_ONE_PROJECT_SELECT = 'STEP_ONE_PROJECT_SELECT';
export const STEP_TWO_PROJECT_WIDTH = 'STEP_TWO_PROJECT_WIDTH';
export const STEP_THREE_ROD_COLLECTION = 'STEP_THREE_ROD_COLLECTION';

export const MACHINE_PROFILE_BOTTOM_HEIGHT = 700;
export const MACHINE_PROFILE_TOP_HEIGHT = 80;

export const MAILBOX_LEFT = 'MAILBOX_LEFT';
export const MAILBOX_CENTER = 'MAILBOX_CENTER';
export const MAILBOX_RIGHT = 'MAILBOX_RIGHT';

export const mailboxPlacementInPolish = {
  [MAILBOX_LEFT]: 'bliżej lewej krawędzi',
  [MAILBOX_CENTER]: 'centralnie',
  [MAILBOX_RIGHT]: 'bliżej prawej krawędzi',
};

export const mailboxPlacements = [
  [MAILBOX_LEFT],
  [MAILBOX_CENTER],
  [MAILBOX_RIGHT],
];
