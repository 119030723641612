import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  align-items: baseline;
`;

const Title = styled.h1`
  font-weight: lighter;
`;

const Body = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const IssueInfo = styled.span`
  font-size: 1.5rem;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-items: flex-start;
  padding: 1rem 2rem;
`;

const Button = styled.button`
  border-radius: 22px;
  padding: 0.5rem 2rem;
`;

const Alert = ({ alert, setAlert }) => {
  if (!alert) {
    return null;
  }

  const ModalTitle = () => {
    switch (true) {
      case alert.title.toString().includes('Nie możesz skopiować kształtownika'):
      case alert.title.toString().includes('Nie możesz zmienić'):
      case alert.title.toString().includes('Nie możesz dodać'):
        return 'WYSTĄPIŁ BŁĄD';
      default:
        return 'JESZCZE COŚ';
    }
  };

  const click = () => setAlert(null);

  return (
    <div id={'alertModal'} className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <ModalHeader>
            <Title>{ModalTitle()}</Title>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={click}>
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <Body className="modal-body">
            <IssueInfo>{alert.title}</IssueInfo>
            <br/>
            <span>{alert.message}</span>
          </Body>
          <BtnContainer>
            <Button type="button" className="btn btn-success" data-dismiss="modal" aria-label="Close" onClick={click}>
              Rozumiem
            </Button>
          </BtnContainer>
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func.isRequired,
};

export default Alert;
