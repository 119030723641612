import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN
} from 'fenceTypesConstants';
import { v4 as uuid_v4 } from 'uuid';
import { PROJECT_WAS_COPIED, SET_CHOSEN_OPTION } from '../../actions';
import { projectBase } from '../Menu/ProjectType';
import styled from 'styled-components';
import { DIY_MODEL } from '../../ProjectConstants';

const ModalHeader = styled.div`
  display: flex;
  padding: 2rem;
`;

const Title = styled.h1`
  font-weight: lighter;
`;

const Body = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 1rem;
`;

const Info = styled.span`
  font-size: 1.5rem;
  padding: 1rem;
`;

const Select = styled.select`
  border-radius: 22px;
  border: none;
  background: #e5e5e5;
  color: #000000;
  width: 50%;
  
  &:focus { 
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const Btn = styled.button`
  border-radius: 22px;
  padding: 0.5rem 2rem;
  margin-right: 1rem;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const findProjectsInType = (projects, type) => projects.filter((p) => p.type === type);

const CopyButton = () => {
  const dispatch = useDispatch();

  const [ showModal, setShowModal ] = useState(true);

  const projects = useSelector(state => state.app.projects);
  const numberOfProjectsPerType = useSelector(state => state.app.numberOfProjectsPerType);
  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));

  const projectsToCopyNotFiltered = [...projects];
  const projectsToCopy = projectsToCopyNotFiltered.filter((p) => p.rods.length);
  projectsToCopy.sort((p) => p.type === FENCE_TYPE_SPAN ? 1 : 0);
  const [projectIndexToCopy, setProjectIndexToCopy] = useState(0);

  if (!project || project.rods.length || !projectsToCopy.length) {
    return null;
  }

  const fenceType = (type) => {
    switch (type) {
      case FENCE_TYPE_GATE_SELF_SUPPORTING:
        return 'Brama samonośna';
      case FENCE_TYPE_GATE_TILTING:
        return 'Brama uchylna';
      case FENCE_TYPE_GATEWAY:
        return 'Furtka';
      case FENCE_TYPE_SPAN:
        return 'Przęsło';
      default:
        throw new Error('Unknown type.');
    }
  };

  const options = projectsToCopy.map((p, index) => (
    <option key={index} value={index}>
      {fenceType(p.type)} {p.indexForHuman} (H: {p.heightExpected})
    </option>
  ));

  const setAdditionalElementTop = (projectToCopy, newProjectBase) => {

    if (projectToCopy.type === FENCE_TYPE_SPAN && project.type !== FENCE_TYPE_SPAN) {
      const newAdditionalElementTop = {
        ...newProjectBase.additionalElementTop,
        space: projectToCopy.defaultSpace,
      };

      return projectToCopy.additionalElementTop ? {
        ...newProjectBase.additionalElementTop,
        space: projectToCopy.additionalElementTop.space,
      } : newAdditionalElementTop;
    } else {
      return projectToCopy.additionalElementTop ? {
        ...newProjectBase.additionalElementTop,
        space: projectToCopy.additionalElementTop.space,
      } : newProjectBase.additionalElementTop;
    }
  };

  const click = () => {
    let rodsToCopy;

    const projectToCopy = projectsToCopy[projectIndexToCopy];
    const newProjectBase = projectToCopy.type === project.type ? projectToCopy : projectBase(project.type, numberOfProjectsPerType);

    if (projectToCopy.type === FENCE_TYPE_SPAN && project.type !== FENCE_TYPE_SPAN) {
      const tempRodsToCopy = projectToCopy.rods.slice();
      const lastRod = tempRodsToCopy.pop();
      tempRodsToCopy.push({
        id: lastRod.id,
        size: lastRod.size,
        space: projectToCopy.defaultSpace,
        disabled: true,
      });

      rodsToCopy = tempRodsToCopy;
    } else {
      rodsToCopy = projectToCopy.rods;
    }
    const newProject = {
      ...newProjectBase,
      id: project.id,
      indexForHuman: project.indexForHuman,
      rods: rodsToCopy,
      width: projectToCopy.width,
      height: projectToCopy.height,
      heightExpected: projectToCopy.heightExpected,
      totalSpace: projectToCopy.totalSpace,
      defaultSpace: projectToCopy.defaultSpace,
      additionalElementTop: setAdditionalElementTop(projectToCopy, newProjectBase),
    };
    newProject.rods = newProject.rods.map((rod) => ({
      ...rod,
      rodForMachine: false,
      rodForMailbox: false,
      id: uuid_v4(),
    }));

    dispatch({type: PROJECT_WAS_COPIED, updatedProject: newProject});
  };

  const change = (e) => setProjectIndexToCopy(parseInt(e.target.value));

  const newProject = () => {
    setShowModal(false);
    dispatch({ type: SET_CHOSEN_OPTION, chosenOption: DIY_MODEL });
  };

  if (showModal === true) {
    return (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <ModalHeader>
              <Title>MOŻNA ŁATWIEJ</Title>
            </ModalHeader>
            <Body className="modal-body">
              <Info>
                Stwórz kolejny projekt na bazie już istniejącego,<br/>co ułatwi i przyspieszy projektowanie ogrodzenia.
              </Info>
              <Container>
                <label htmlFor="selectProjectToCopy">Wybierz projekt, który chcesz powielić:</label>
                <SelectContainer>
                  <Select
                    className="form-control"
                    id="selectProjectToCopy"
                    value={projectIndexToCopy ?? ''}
                    onChange={change}
                  >
                    <option value="" disabled>Wybierz:</option>
                    {options}
                  </Select>
                  <div>
                    <Btn
                      className="btn btn-success"
                      type="button"
                      onClick={click}
                    >
                      <span><b>Powiel</b></span>
                    </Btn>
                    <Btn
                      className="btn btn-primary"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={newProject}
                    >
                      <span>Stwórz nowy projekt</span>
                    </Btn>
                  </div>
                </SelectContainer>
              </Container>
            </Body>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CopyButton;
