import React from 'react';
import PropTypes from 'prop-types';
import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN,
  fencesTypesInPolish
} from 'fenceTypesConstants';
import Signature from './Signature';
import { mailboxPlacementInPolish } from '../../ProjectConstants';

const ProjectTable = ({ project, pdfOptions }) => {
  const cells = () => {
    const arr = [];

    let no = 1;

    // we have to push one by one for further calculation of rows
    if (project.additionalElementTop && project.type !== FENCE_TYPE_SPAN) {
      arr.push(<td># {no}</td>);
      arr.push(<td>Profil {project.additionalElementTop.size} mm<br/>(RAMA GÓRNA)</td>);
      arr.push(<td># {no + 1}</td>);
      arr.push(<td>Odstęp {project.additionalElementTop.space} mm</td>);
      no += 2;
    }

    project.rods.forEach((rod) => {
      if (rod.rodForMachine) {
        arr.push(<td><b># {no}</b></td>);
        arr.push(<td><b>Profil {rod.size} mm</b></td>);
      } else if (project.mailbox && rod.rodForMailbox) {
        arr.push(<td><b># {no}</b></td>);
        arr.push(<td><b>Profil {rod.size} mm<br/>(Skrzynka Invisible)</b></td>);
      } else {
        arr.push(<td># {no}</td>);
        arr.push(<td>Profil {rod.size} mm</td>);
      }
      arr.push(<td># {no + 1}</td>);
      arr.push(<td>Odstęp {rod.space} mm</td>);
      no += 2;
    });

    if (project.additionalElementBottom) {
      arr.push(<td># {no}</td>);
      arr.push(<td>Profil {project.additionalElementBottom.size} mm</td>);
    }

    return arr;
  };

  const Body = () => {
    const cellsArr = cells();
    const rowsNumber = Math.round(cellsArr.length / 9);
    const multiplier = rowsNumber * 2;

    const rowsArr = [];
    for (let row = 0; row < rowsNumber; row++) {
      const col1 = row > 0 ? row * 2 : 0;
      const col2 = col1 + 1;
      const col3 = col1 + multiplier;
      const col4 = col3 + 1;
      const col5 = col3 + multiplier;
      const col6 = col5 + 1;
      const col7 = col5 + multiplier;
      const col8 = col7 + 1;
      const col9 = col7 + multiplier;
      const col10 = col9 + 1;

      rowsArr.push(
        <tr key={row}>
          {cellsArr[col1]}
          {cellsArr[col2]}
          {cellsArr[col3]}
          {cellsArr[col4]}
          {cellsArr[col5]}
          {cellsArr[col6]}
          {cellsArr[col7]}
          {cellsArr[col8]}
          {cellsArr[col9]}
          {cellsArr[col10]}
        </tr>
      );
    }

    return rowsArr;
  };

  const numberOfRodPerTypeArr = {
    20: 0,
    40: 0,
    80: 0,
    100: 0,
  };

  const getMachineRodHeight = () => {
    const machineIndex = project.rods.findIndex((rod) => rod.rodForMachine === true);
    const rodsAboveMachine = project.rods.slice(0, machineIndex);
    const sumOfRodsAboveMachine = rodsAboveMachine.reduce((accumulator, rod) => {
      return accumulator + parseInt(rod.size) + parseInt(rod.space)
    }, 0);
    const rodsSum = sumOfRodsAboveMachine + project.rods[machineIndex].size + project.additionalElementTop.size + project.additionalElementTop.space;

    return project.height - rodsSum;
  };

  project.rods.map((rod) => rod.rodForMachine === true ? numberOfRodPerTypeArr["80x40 - wzmocnienie do automatyki"] = 1 : numberOfRodPerTypeArr[rod.size] = numberOfRodPerTypeArr[rod.size] + 1);

  const shouldMachinePartShow = project.type === FENCE_TYPE_GATE_TILTING && project.gateTiltingMachine && project.rods.some((rod) => rod.rodForMachine === true);
  const shouldMailboxPartShow = (project.type === FENCE_TYPE_GATEWAY || project.type === FENCE_TYPE_SPAN) && project.mailbox && project.mailboxPlacement;
  const shouldWidthPartShow = project.type === FENCE_TYPE_SPAN || project.type === FENCE_TYPE_GATE_TILTING || project.type === FENCE_TYPE_GATE_SELF_SUPPORTING;

  const numberOfRodsPerType = () => Object.keys(numberOfRodPerTypeArr).map((size) => {
    return <tr key={size}>
      <td>{numberOfRodPerTypeArr[size]}</td>
      <td>{size.length > 3 ? "80x40 - wzmocnienie do automatyki" : `${size} mm`}</td>
    </tr>;
  });

  return (
    <div className="new-page">
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <div className="headerSection">
              <h1>{fencesTypesInPolish[project.type]} {project.indexForHuman}</h1>
            </div>
            <div className="section">
              <div>
                <h2>Wymiar</h2>
              </div>
              <div>
                {pdfOptions.showWidth && <p>Szerokość {project.width} mm<br/><small><upper>*</upper> Producent dopuszcza tolerancję dla szerokości w zakresie 10 mm.</small></p>}
                <p>Wysokość {project.height} mm<br/><small><upper>*</upper> Producent dopuszcza tolerancję dla wysokości w zakresie 10 mm.</small></p>
              </div>
            </div>
            <div className="section">
              <div>
                <h2>Rozkład kształtowników</h2>
              </div>
              <table className="table table-striped">
                <thead>
                <tr>
                  <th className="thSingular">LP</th>
                  <th className="thRod">Kształtownik</th>
                  <th className="thSingular">LP</th>
                  <th className="thRod">Kształtownik</th>
                  <th className="thSingular">LP</th>
                  <th className="thRod">Kształtownik</th>
                  <th className="thSingular">LP</th>
                  <th className="thRod">Kształtownik</th>
                  <th className="thSingular">LP</th>
                  <th className="thRod">Kształtownik</th>
                </tr>
                </thead>
                <tbody>
                <Body/>
                </tbody>
              </table>
              {shouldMachinePartShow && (
                <span><b>Pogrubiony profil stanowi przygotowanie pod automatykę. Znajduje się na wysokości {getMachineRodHeight()} mm.</b></span>)}
              {shouldMailboxPartShow && (
                <span><b>
                  Pogrubiony profil stanowi profil, na który należy zamontować skrzynkę Invisible. Znajduje się {mailboxPlacementInPolish[project.mailboxPlacement]}.
                  {' '}
                  Pamiętaj, że wskazanie położenia skrzynki na listy (lewo, centralnie, prawo) ma charakter orientacyjny.
                  {' '}
                  Dokładne umiejscowienie skrzynki na listy wynika również z przyjętej szerokości oraz innych wartości niezbędnych do produkcji przęsła.
                </b></span>)}
            </div>
            <div className="section">
              <div>
                <h2>Ilość kształtowników / rozmiar</h2>
              </div>
              <div>
                <table id="numberOfRodsPerTypeTable" className="table table-striped">
                  <thead>
                  <tr>
                    <th>Ilość</th>
                    <th>Rozmiar</th>
                  </tr>
                  </thead>
                  <tbody>
                  {numberOfRodsPerType()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {shouldMachinePartShow && (
            <div className="machineConsent">
              <span>Akceptuję informacje oraz wskazówki dotyczące montażu automatyki bramowej.</span>
            </div>
          )}
          {shouldWidthPartShow && (
            <div style={{position: "absolute", top: "1250px", fontSize: "12.5"}} className="machineConsent">
              <span>*Uwaga:<br/>
                {" "}
                Wszystkie projekty modelu Diverso zawierające kształtownik o przekroju 20x20 mm zostają <br/>
                dodatkowo wzmocnione płaskownikiem umiejscowionym na środku. Płaskownik jest stosowany <br/>
                od szerokości 1500 mm i dotyczy zarówno przęseł jak i wypełnienia w bramach samonośnych <br/>
                i uchylnych oraz jednoskrzydłowych i łamanych.
            </span>
            </div>
          )}
        </div>
      </div>
      <Signature/>
    </div>
  );
};

ProjectTable.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectTable;
