import {connect} from 'react-redux';
import {addProject, setCurrentProjectId, setExampleModel} from '../actions';
import ShouldLoadExampleModelAlert from '../components/ShouldLoadExampleModelAlert';

const mapStateToProps = (state) => ({
  exampleModel: state.app.exampleModel,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentProjectId: (currentProjectId) => dispatch(setCurrentProjectId(currentProjectId)),
  addProject: (project) => dispatch(addProject(project)),
  setExampleModel: (exampleModel) => dispatch(setExampleModel(exampleModel)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShouldLoadExampleModelAlert);
