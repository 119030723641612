import React, {useState} from 'react';
import {faPaperPlane, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { v4 as uuid_v4 } from 'uuid';

import {SET_SHOW_FINISH_PROJECT_FORM} from '../../actions';
import {useDispatch, useSelector} from 'react-redux';
import { generatePdf, generateHtml, PDF_GENERATE_OPTION_ALL } from '../Pdf/PdfBtn';
import {API_URL} from '../App';
import styled from 'styled-components';
import ProjectOption from '../Pdf/Modal/ProjectOption';
import WidthOption from '../Pdf/Modal/WidthOption';

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: lighter;
`;

const ModalBody = styled.div`
  padding: 1rem 3rem;
  max-height: 700px;
  overflow-y: auto;
`;

const Input = styled.input`
  border-radius: 25px;
  padding: 0.25rem 2rem;
  
  &:active {
    outline: none;
    box-shadow: none;
    border: 1px solid #575b5d;
    background: #ffffff;
  }
  
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #575b5d;
    background: #ffffff;
  }
`;

const Label = styled.label`
  text-align: justify;
  padding-right: 1rem;
  font-size: 10px;
`;

const FormLabel = styled.label`
  font-size: 0.75rem;
`;

const ChosenOptions = styled.div`
  padding: 0 2rem;
`;

const FinishBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FinishBtn = styled.button`
  border-radius: 25px;
  padding: 0.5rem 2rem;
`;

export const projectIdParam = 'p';

const FinishProjectForm = () => {
  const projects = useSelector(state => state.app.projects);
  const pdfOptions = useSelector(state => state.app.pdf);
  const showWidth = useSelector(state => state.app.pdf.showWidth);

  const dispatch = useDispatch();

  const [option, setOption] = useState(PDF_GENERATE_OPTION_ALL);
  const [projectName, setProjectName] = useState(null);
  const [projectNameError, setProjectNameError] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [consentApproved, setConsentApproved] = useState(false);
  const [showConsentError, setShowConsentError] = useState(false);
  const [response, setResponse] = useState(null);
  const [processing, setProcessing] = useState(false);

  const close = (e) => {
    e.preventDefault();

    dispatch({type: SET_SHOW_FINISH_PROJECT_FORM, showFinishProjectForm: false});
  };

  const projectNameChange = (e) => {
    setProjectName(e.target.value);
    setProjectNameError(null);
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const Consent = () => {
    const change = () => {
      setConsentApproved(!consentApproved);
      setShowConsentError(consentApproved);
    };

    return (
      <div className="form-group form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="consent"
          onChange={change}
          checked={consentApproved}
        />
        <Label className="form-check-label" htmlFor="consent">
          <>Poprzez naciśniecie przycisku wyślij wyrażam zgodę na kontakt ze mną przez Firmę Konsport z siedzibą przy ul. Dobków 1, w mieście Wodzierady k. Łodzi lub na kontakt ze mną przez Firmę partnerską, z którą Konsport współpracuje w ramach tzw. Sieci dystrybutorów Konsport w celu przekazywania mi informacji w związku z prowadzoną ofertą realizacji projektu modelu Diverso, dostępnego w ramach oferty ogrodzeń Konsport. Podanie danych jest dobrowolne. Przysługuje Ci prawo dostępu do swoich danych, poprawienia, usunięcia danych zarówno w firmie Konsport jak i u każdego Dystrybutora.<br/>Lista dystrybutorów Konsport dostępna jest również tutaj - <a
            href="https://konsport.com.pl/gdzie-kupic" target="_blank" rel="noopener noreferrer">konsport.com.pl/gdzie-kupic</a></>
        </Label>
        {showConsentError && <small className="form-text error">Zaakceptuj zgodę</small>}
      </div>
    );
  };

  const validate = () => {
    let valid = true;
    const projectNameMax = 255;
    if (projectName?.length > projectNameMax) {
      setProjectNameError(`Nazwa projektu nie może mieć więcej niż ${projectNameMax} znaków.`);
      valid = false;
    }
    if (!email) {
      setEmailError('Wypełnij email.');
      valid = false;
    }
    if (!consentApproved) {
      setShowConsentError(true);
      valid = false;
    }

    return valid;
  };

  const submit = (e) => {
    e.preventDefault();

    setProcessing(true);

    const valid = validate();

    if (!valid) {
      setProcessing(false);

      return;
    }

    setResponse(null);

    const html = generateHtml(projects, pdfOptions);

    const errorMessage = 'Wystąpił błąd. Skontaktuj się z Konsport.';

    generatePdf(html).then((response) => response.json()).then((response) => {
      if (response.url) {
        const pdfUrl = response.url;

        const body = {
          id: uuid_v4(),
          projectName,
          email,
          projects,
          pdfUrl,
        };

        fetch(`${API_URL}/projects`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }).then((response) => response.json()).then((response) => {
          if (response.projectId) {
            const projectUrl = `${window.location.protocol}//${window.location.host}/?${projectIdParam}=${response.projectId}`;
            setResponse(
              <>
                <p>{response.message}</p>
                <p>Projekt został zapisany pod adresem <a href={projectUrl} target="_blank" rel="noopener noreferrer">{projectUrl}</a></p>
              </>
            );
          } else {
            setResponse(<p>{errorMessage}</p>);
          }
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error', error);
          setResponse(<p>{errorMessage}</p>);
        }).finally(() => setProcessing(false));
      } else {
        // eslint-disable-next-line no-console
        console.log('error', response);
        setResponse(<p>{errorMessage}</p>);
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
      setResponse(<p>{errorMessage}</p>);
    }).finally(() => setProcessing(false));
  };

  return (
    <div className="modal" tabIndex="-1" role="dialog" style={{display: 'block'}}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <ModalHeader>
            <Title className="modal-title">Finalizacja projektu</Title>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <form onSubmit={submit} id="finishProjectForm">
              <div className="form-group">
                <FormLabel htmlFor="projectName">Nazwa projektu</FormLabel>
                <Input
                  type="text"
                  className="form-control"
                  id="projectName"
                  placeholder="Nazwa projektu"
                  onChange={projectNameChange}
                  value={projectName || ''}
                  autoComplete={'off'}
                />
                <small className="form-text error">{projectNameError}</small>
              </div>
              <div className="form-group">
                <FormLabel htmlFor="email">E-mail</FormLabel>
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="E-mail"
                  onChange={emailChange}
                  value={email || ''}
                  autoComplete={'off'}
                />
                <small className="form-text error">{emailError}</small>
              </div>
              <ProjectOption option={option} setOption={setOption}/>
              <WidthOption/>
              <ChosenOptions>
                <p>Wybrałeś opcje:</p>
                <ul>
                  <li>{option === PDF_GENERATE_OPTION_ALL ? 'Generuj wszystkie projekty' : 'Generuj jeden (obecny) projekt'}</li>
                  <li>{showWidth ? 'Pokaż szerokość' : 'Ukryj szerokość'}</li>
                </ul>
              </ChosenOptions>
              <Consent/>
              <FinishBtnContainer id="finishProjectFormSubmitRow">
                <FinishBtn
                  className={'btn btn-success btn-icon-left btn-spinner'}
                  type={'submit'}
                >
                  {processing && <FontAwesomeIcon className="spinner" icon={faSpinner} size={'2x'}/>}
                  {!processing && <FontAwesomeIcon icon={faPaperPlane}/>}
                  <span>Wyślij</span>
                </FinishBtn>
              </FinishBtnContainer>
              <div className="form-group">
                {response}
              </div>
            </form>
          </ModalBody>
        </div>
      </div>
    </div>
  );
};

export default FinishProjectForm;
