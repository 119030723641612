import {useFormikContext} from 'formik';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN
} from 'fenceTypesConstants';
import { gateSelfSupportingDefaultAdditionalElementTopSize } from './ProjectType';
import MenuInput from './MenuInput';
import Tooltip from './Tooltip';
import { DIY_MODEL, STEP_TWO_PROJECT_WIDTH } from '../../ProjectConstants';
import { DISAPPEARING_TIMEOUT } from '../App';
import {
  InputContainer, MenuInputBtn,
  MenuInputBtnContainer,
  MenuInputLabel,
  MenuInputsContainer, MenuInputSmall,
  MenuInputText,
} from './MenuHeight';
import {Extra} from './Context';

const getMax = (type) => {
  switch (type) {
    case 'gateway':
      return 1500;
    case 'span':
      return 3000;
    default:
      return 5999;
  }
}

const MenuWidth = ({ updateProject, setOpenTooltip }) => {
  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));
  const formik = useFormikContext();
  const index = formik.values.projects.findIndex(p => p.id === project.id);
  const [info, setInfo] = useState(null);
  const [tmpVal, setTmpVal] = useState(project.width || '');
  const [timer, setTimer] = useState(null);

  useEffect(() => setTmpVal(project.width), [project.width]);

  const chosenOption = useSelector(state => state.app.chosenOption) || null;

  const updateDependenciesForGateSelfSupporting = (width, project) => {
    let newProject = { ...project };

    if (newProject.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
      const widthCase = 5800;
      const topNewSize = gateSelfSupportingDefaultAdditionalElementTopSize;
      const bottomNewSize = width > widthCase ? 120 + 80 + 2 : 162;

      newProject = {
        ...newProject,
        additionalElementTop: {
          ...newProject.additionalElementTop,
          size: topNewSize,
        },
        additionalElementBottom: {
          ...newProject.additionalElementBottom,
          size: bottomNewSize,
        },
      };
    }

    return newProject;
  };

  const getLabel = () => {
    if (project.type === FENCE_TYPE_SPAN) {
      return 'Szerokość przęsła';
    } else if (project.type === FENCE_TYPE_GATEWAY) {
      return 'Szerokość furtki';
    } else if (project.type === FENCE_TYPE_GATE_TILTING) {
      return 'Szerokość bramy uchylnej';
    } else if (project.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
      return 'Szerokość bramy samonośnej';
    } else {
      return 'Szerokość';
    }
  };

  const change = (newVal, clickThroughButton) => {
    const min = 500;
    const max = getMax(project.type);
    clearTimeout(timer);

    setInfo(null);

    if (!project) {
      setInfo('Wybierz rodzaj projektu');

      return;
    }

    newVal = parseInt(newVal) || '';
    setTmpVal(newVal);

    const newProject = updateDependenciesForGateSelfSupporting(newVal, project);

    let err;
    if (typeof newVal === 'number') {
      if (newVal < min) {
        err = `Min. szerokość to ${min} mm.`;
      } else if (newVal > max) {
        err = `Maks. szerokość to ${max} mm.`;
      }
    }

    const setValues = () => {
      if (err) {
        setInfo(err);
      } else {

        updateProject({
          ...newProject,
          width: newVal,
        });
        formik.setFieldValue(`projects[${index}]width`, newVal);
        setInfo('Zapisano.');
        setTimeout(() => setInfo(null), DISAPPEARING_TIMEOUT);
      }
    };

    if (clickThroughButton) {
      setValues();
    } else {
      setTimer(setTimeout(() => {
        setValues();
      }, 700));
    }
  };

  if (!tmpVal && chosenOption === DIY_MODEL) {
    setOpenTooltip(true);
  } else {
    setOpenTooltip(false);
  }

  return (
    <MenuInputsContainer className={'form-group'}>
      <MenuInputLabel htmlFor="width">{getLabel()}</MenuInputLabel>
      <InputContainer>
        <MenuInputText>mm</MenuInputText>
        <MenuInput
          id="width"
          step={10}
          change={(e) => change(e.target.value, false)}
          value={tmpVal || ''}
          sentry="width"
        />
        {!tmpVal && chosenOption === DIY_MODEL && <Tooltip situation={STEP_TWO_PROJECT_WIDTH}/>}
        <MenuInputBtnContainer>
          <MenuInputBtn style={{ marginRight: '0.5rem' }} onClick={() => change(parseInt(project.width + 10), true)}>
            <FontAwesomeIcon icon={faPlus} style={{ color: '#ffffff' }}/>
          </MenuInputBtn>
          <MenuInputBtn onClick={() => change(parseInt(project.width - 10), true)}>
            <FontAwesomeIcon icon={faMinus} style={{ color: '#ffffff' }}/>
          </MenuInputBtn>
        </MenuInputBtnContainer>
      </InputContainer>
      <Extra><upper>*</upper> Producent dopuszcza tolerancję dla szerokości w zakresie 10 mm.</Extra>
      <MenuInputSmall className="form-text">{info}</MenuInputSmall>
    </MenuInputsContainer>
  );
};

MenuWidth.propTypes = {
  updateProject: PropTypes.func.isRequired,
};

export default MenuWidth;
