import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DISAPPEARING_TIMEOUT } from '../App';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FENCE_TYPE_SPAN } from 'fenceTypesConstants';

const Container = styled.div`
  padding: 1rem 2rem 0.5rem 2rem;
  display: flex;
  border-radius: 50px;
  background: #f2f2f2;
  width: 100%;
  position: relative;
  align-items: baseline;
  ${props => props.isAdditionalElement && 'justify-content: space-between'};
`;

const InputText = styled.span`
   position: absolute;
   color: #808080;
   top: 23px;
   left: 203px;
`;

const Input = styled.input`
  width: 150px;
  border-radius: 50px;
  border: 1px solid #808080;
  background: transparent;
  color: #000000;
  margin-top: -8px;
  margin-bottom: 8px;
  font-weight: bold;
  padding-left: 1.5rem;
  
  &:focus {
    background: transparent;
    outline: none;
    border: 1px solid #808080;
    box-shadow: none;
  }
`;

const Label = styled.label`
  margin-right: 2rem;
`;

const InputBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
`;

const InputBtn = styled.button`
  border-radius: 50%;
  padding: 0.35rem 0.70rem;
  background: transparent;
  color: #808080;
  border: 1px solid #808080;
  &:focus {
    border: 1px solid #808080;
    outline: none;
  }
`;

const Small = styled.small`
  margin-left: 0.75rem;
  color: #808080;
`;

export const calcProjectHeight = (project) => {
  let height = 0;

  height += project.additionalElementTop && project.additionalElementTop.space ? project.additionalElementTop.space : 0;
  height += project.additionalElementTop && project.additionalElementTop.size ? project.additionalElementTop.size : 0;

  height += project.rods.reduce((h, rod) => h + rod.size + rod.space, 0);

  height += project.additionalElementBottom && project.additionalElementBottom.space ? project.additionalElementBottom.space : 0;
  height += project.additionalElementBottom && project.additionalElementBottom.size ? project.additionalElementBottom.size : 0;

  return height;
};

const RodSpace = ({ id, space, min = 10, disabled = false, project, isAdditionalElementTop = false, setAlert, updateProject }) => {
  const [timer, setTimer] = useState(null);
  const [saved, setSaved] = useState(null);
  const [spaceTmp, setSpaceTmp] = useState(space || project.defaultSpace || '');

  useEffect(() => {
      if (space !== project.defaultSpace) {
        setSpaceTmp(space);
      } else {
        setSpaceTmp(project.defaultSpace || '')
      }
    },
    [space, project.defaultSpace]);

  const change = (newVal, clickThroughButton) => {
    clearTimeout(timer);

    let title;

    const max = 2000;
    const newProject = { ...project };

    setSpaceTmp(parseInt(newVal));

    if (isAdditionalElementTop) {
      newProject.additionalElementTop.space = newVal;
    } else {
      newProject.rods = project.rods.map((rod) => rod.id === id ? {
        ...rod,
        space: newVal,
      } : rod);
    }

    const heightAfterUpdate = calcProjectHeight(newProject);

    if (isNaN(newVal)) {
      title = `Minimalny odstęp to ${min} mm.`;
    }
    if (newVal < min) {
      title = `Nie możesz zmienić odstępu. Minimalny odstęp to ${min} mm.`;
    }
    if (newVal > max) {
      title = `Nie możesz zmienić odstępu. Maksymalny odstęp to ${max} mm.`;
    }
    if (heightAfterUpdate > project.heightExpected && newVal > space) {
      if (project.heightExpected - project.height > 0) {
        title = `Nie możesz dodać odstępu. Pozostało Ci ${project.heightExpected - project.height} mm.`;
      } else {
        title = 'Nie możesz zmienić odstępu. Projekt przekracza przewidzianą wysokość.';
      }
    }

    const setValues = () => {
      if (title) {
        setAlert({ title });
        setSpaceTmp(space);
      } else {
        updateProject(newProject);
        setSaved('Zapisano.');
        setTimeout(() => setSaved(null), DISAPPEARING_TIMEOUT);
      }
    };

    if (clickThroughButton) {
      setValues();
    } else {
      setTimer(setTimeout(() => {
        setValues();
      }, 700));
    }
  };

  if (!isAdditionalElementTop && disabled && project.type === FENCE_TYPE_SPAN) {
    return null;
  }

  return (
    <Container isAdditionalElement={isAdditionalElementTop || disabled}>
      {isAdditionalElementTop ? (
        <span>Odstęp {project.defaultSpace} mm</span>
      ) : (
        <>
          <Label htmlFor={`space__${id}`}>Odstęp</Label>
          <InputText>mm</InputText>
          <Input
            type="number"
            className="form-control"
            id={`space__${id}`}
            value={spaceTmp || ''}
            onChange={(e) => change(parseInt(e.target.value), false)}
            disabled={disabled}
          />
          {!disabled &&
          <>
            <InputBtnContainer>
              <InputBtn style={{ marginRight: '0.5rem' }} onClick={() => change(space + 1, true)}>
                <FontAwesomeIcon icon={faPlus} style={{ color: '#808080' }}/>
              </InputBtn>
              <InputBtn onClick={() => change(space - 1, true)}>
                <FontAwesomeIcon icon={faMinus} style={{ color: '#808080' }}/>
              </InputBtn>
            </InputBtnContainer>
            <Small className="form-text">{saved}</Small>
          </>
          }
        </>)}
      {isAdditionalElementTop || disabled ? <span> Element wymagany</span> : ''}
    </Container>
  )
};

RodSpace.propTypes = {
  id: PropTypes.string.isRequired,
  space: PropTypes.number.isRequired,
  min: PropTypes.number,
  disabled: PropTypes.bool,
  project: PropTypes.object.isRequired,
  isAdditionalElementTop: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
};

export default RodSpace;
