import React from 'react';
import PropTypes from 'prop-types';
import {Visualisation} from '../ProjectVisualisation/ProjectVisualisation';
import Signature from './Signature';

const ProjectVisualisation = ({project}) => {

  const projectVisualisation = {
    scale: 4,
  };

  return (
    <div className="new-page">
      <div className="container-xl">
        <div className="row">
          <div className="col">

            <div className="section">
              <div>
                <h2>Wizualizacja</h2>
              </div>
              <div>
                <Visualisation p={project} projectVisualisation={projectVisualisation}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Signature/>
    </div>
  );
};

ProjectVisualisation.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectVisualisation;
