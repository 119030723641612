import { combineReducers } from 'redux';
import {
  ADD_PROJECT,
  CLOSED_MODAL_COPIED_PROJECT,
  PROJECT_WAS_COPIED,
  REMOVE_PROJECT,
  SET_ALERT,
  SET_CHOSEN_OPTION,
  SET_CURRENT_PROJECT_ID,
  SET_EXAMPLE_MODEL,
  GATE_TILTING_MACHINE_CHECKED,
  SET_MENU,
  SET_PDF_SHOW_MODAL,
  SET_PDF_SHOW_WIDTH,
  SET_PROJECT_VISUALISATION,
  SET_PROJECTS,
  ROD_FOR_MACHINE_CHOSEN,
  SET_SHOW_FINISH_PROJECT_FORM,
  MACHINE_MODAL_IS_SHOWING,
  UPDATE_PROJECT,
  EXAMPLE_PROJECT_WAS_CHOSEN,
  MAILBOX_CHECKED,
  MAILBOX_MODAL_IS_SHOWING,
  ROD_FOR_MAILBOX_CHOSEN,
  MAILBOX_PLACEMENT_CHOSEN, WIDTH_MODAL_IS_SHOWING
} from 'actions';
import update from 'immutability-helper';
import { calcProjectHeight } from 'components/Rod/RodSpace';
import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN
} from 'fenceTypesConstants';
import changeRodsForSpanRequirements from './changeRodsForSpanRequirements';
import { MAX_SCALE } from 'components/ProjectVisualisation/ProjectVisualisation';

const appInitialState = {
  canStartDesigning: false,
  currentProjectId: null,
  showFinishProjectForm: false,
  mailboxModalIsShowing: false,
  widthModalIsShowing: false,
  exampleModel: {
    used: null,
    nextFenceType: null,
    showAlert: false,
  },
  alert: null,
  projectVisualisation: {
    show: false,
    scale: MAX_SCALE,
    allProjects: false,
  },
  menu: {
    height: {
      min: 10,
    },
  },
  projects: [],
  numberOfProjectsPerType: {
    [FENCE_TYPE_GATE_SELF_SUPPORTING]: 0,
    [FENCE_TYPE_GATE_TILTING]: 0,
    [FENCE_TYPE_GATEWAY]: 0,
    [FENCE_TYPE_SPAN]: 0,
  },
  pdf: {
    showWidth: true,
    showModal: false,
  },
};

const canStartDesigning = (project) => !!(project && project.type);

const setExampleProject = (state, action) => {
  const newProjects = state.projects.slice();
  const totalSpace = calcProjectTotalSpace(action.project);
  const newProject = {
    ...action.project,
    totalSpace,
  };
  newProjects.push(newProject);

  const newNumberOfProjects = state.numberOfProjectsPerType[action.project.type] + 1;
  return {
    ...state,
    numberOfProjectsPerType: {
      ...state.numberOfProjectsPerType,
      [action.project.type]: newNumberOfProjects,
    },
    canStartDesigning: canStartDesigning(action.project),
    currentProjectId: action.currentProjectId,
    projects: newProjects,
    exampleModel: action.exampleModel,
    chosenOption: action.chosenOption,
  };
};

const addProject = (state, project) => {
  let newProject;
  const newProjects = state.projects.slice();
  const totalSpace = calcProjectTotalSpace(project);


  if (project.type === FENCE_TYPE_GATE_TILTING) {
    newProject = {
      ...project,
      totalSpace,
      gateTiltingMachine: true,
    };
  } else {
    newProject = {
      ...project,
      totalSpace,
    };
  }
  newProjects.push(newProject);

  const newNumberOfProjects = state.numberOfProjectsPerType[project.type] + 1;
  if (project.type === FENCE_TYPE_GATE_TILTING) {
    return {
      ...state,
      numberOfProjectsPerType: {
        ...state.numberOfProjectsPerType,
        [project.type]: newNumberOfProjects,
      },
      canStartDesigning: canStartDesigning(project),
      currentProjectId: project.id,
      projects: newProjects,
      machineModalIsShowing: true,
      widthModalIsShowing: true,
    };
  } else if (project.type === FENCE_TYPE_GATE_SELF_SUPPORTING || project.type === FENCE_TYPE_SPAN) {
    return {
      ...state,
      numberOfProjectsPerType: {
        ...state.numberOfProjectsPerType,
        [project.type]: newNumberOfProjects,
      },
      canStartDesigning: canStartDesigning(project),
      currentProjectId: project.id,
      projects: newProjects,
      widthModalIsShowing: true,
    };
  } else {
    return {
      ...state,
      numberOfProjectsPerType: {
        ...state.numberOfProjectsPerType,
        [project.type]: newNumberOfProjects,
      },
      canStartDesigning: canStartDesigning(project),
      currentProjectId: project.id,
      projects: newProjects,
    };
  }
};

const setProjects = (state, projects) => {
  const newProjects = [];

  const newNumberOfProjectsPerType = { ...state.numberOfProjectsPerType };
  // eslint-disable-next-line array-callback-return
  projects.map((project) => {
    const totalSpace = calcProjectTotalSpace(project);
    const newProject = {
      ...project,
      totalSpace,
    };
    newProjects.push(newProject);
    newNumberOfProjectsPerType[project.type]++;
  });

  const firstProject = newProjects[0];

  return {
    ...state,
    numberOfProjectsPerType: newNumberOfProjectsPerType,
    canStartDesigning: canStartDesigning(firstProject),
    currentProjectId: firstProject.id,
    projects: newProjects
  };
};

const calcProjectTotalSpace = (project) => {
  let totalSpace = project.additionalElementTop ? project.additionalElementTop.space : 0;

  totalSpace += project.rods.reduce((tS, rod) => tS + rod.space, 0);

  return totalSpace;
};

const updateProject = (state, updatedProject) => {

  let newProject = {
    height: 0,
    totalSpace: 0,
    ...updatedProject,
  };

  if (newProject.type === FENCE_TYPE_SPAN) {
    // must be before calc height
    newProject = changeRodsForSpanRequirements(newProject);
  }

  if (newProject.rods) {
    const height = calcProjectHeight(newProject);
    const totalSpace = calcProjectTotalSpace(newProject);

    newProject = {
      ...newProject,
      height,
      totalSpace,
    };
  }

  const oldProject = state.projects.find((p) => p.id === newProject.id);
  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : p);

  const numberOfProjectsPerType = oldProject.type !== updatedProject.type ? {
    ...state.numberOfProjectsPerType,
    [oldProject.type]: state.numberOfProjectsPerType[oldProject.type] - 1,
    [updatedProject.type]: state.numberOfProjectsPerType[updatedProject.type] + 1,
  } : { ...state.numberOfProjectsPerType };

  return {
    ...state,
    numberOfProjectsPerType,
    canStartDesigning: canStartDesigning(newProject),
    projects: newProjects,
  };
};

const projectWasCopied = (state, updatedProject) => {
  let newProject = {
    height: 0,
    totalSpace: 0,
    ...updatedProject,
  };

  if (newProject.type === FENCE_TYPE_SPAN) {
    // must be before calc height
    newProject = changeRodsForSpanRequirements(newProject);
  }

  if (newProject.rods) {
    const height = calcProjectHeight(newProject);
    const totalSpace = calcProjectTotalSpace(newProject);

    const copiedProjectTooHigh = newProject.heightExpected - (newProject.heightExpected - height) > newProject.heightExpected;

    if (newProject.type === FENCE_TYPE_GATE_TILTING) {
      newProject = {
        ...newProject,
        height,
        totalSpace,
        projectWasCopied: true,
        copiedProjectTooHigh: copiedProjectTooHigh,
        showModalCopiedProject: true,
        gateTiltingMachine: true,
      };
    } else {
      newProject = {
        ...newProject,
        height,
        totalSpace,
        projectWasCopied: true,
        copiedProjectTooHigh: copiedProjectTooHigh,
        showModalCopiedProject: true,
      };
    }
  }

  const oldProject = state.projects.find((p) => p.id === newProject.id);
  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : p);

  const numberOfProjectsPerType = oldProject.type !== updatedProject.type ? {
    ...state.numberOfProjectsPerType,
    [oldProject.type]: state.numberOfProjectsPerType[oldProject.type] - 1,
    [updatedProject.type]: state.numberOfProjectsPerType[updatedProject.type] + 1,
  } : { ...state.numberOfProjectsPerType };

  return {
    ...state,
    numberOfProjectsPerType,
    canStartDesigning: canStartDesigning(newProject),
    projects: newProjects,
  };
};

const setCloseModalAboutCopiedProject = (state, action) => {
  const newProject = {
    ...action.project,
    showModalCopiedProject: action.showModalCopiedProject,
  };

  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : p);

  return {
    ...state,
    projects: newProjects,
  }
};

const removeProject = (state, projectId) => {
  const project = state.projects.find((project) => project.id === projectId);
  const index = state.projects.findIndex((project) => project.id === projectId);

  const projects = update(state.projects, {
      $splice: [
        [index, 1]
      ],
    },
  );

  const newNumberOfProjects = state.numberOfProjectsPerType[project.type] - 1;
  return {
    ...state,
    numberOfProjectsPerType: {
      ...state.numberOfProjectsPerType,
      [project.type]: newNumberOfProjects,
    },
    canStartDesigning: canStartDesigning(project),
    projects: projects
  };
};

const addMachineProfileToProject = (state, action) => {
  const project = state.projects.find((project) => project.id === action.projectId);

  let newProject = {
    ...project,
    gateTiltingMachine: action.gateTiltingMachine,
  };

  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : p);

  return {
    ...state,
    projects: newProjects,
    machineModalIsShowing: action.gateTiltingMachine,
  };
};

const setRodForMachine = (state, action) => {
  const project = state.projects.find((project) => project.id === action.projectId);
  const newRods = project.rods.map((rod) => rod.id === action.rod.id ? action.rod : rod);

  const newProject = {
    ...project,
    rods: newRods,
    machineRodChosen: action.machineRodChosen,
  };

  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : p);

  return {
    ...state,
    projects: newProjects,
    };
};

const addMailboxToProject = (state, action) => {
  const project = state.projects.find((project) => project.id === action.projectId);

  let newProject = {
    ...project,
    mailbox: action.mailbox,
  };

  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : {
    ...p,
    mailbox: false,
    mailboxPlacement: null,
  });

  return {
    ...state,
    projects: newProjects,
    mailboxModalIsShowing: action.mailbox,
  };
};

const setRodForMailbox = (state, action) => {
  const project = state.projects.find((project) => project.id === action.projectId);
  const newRods = project.rods.map((rod) => rod.id === action.rod.id ? action.rod : {
    ...rod,
    rodForMailbox: false,
  });

  const newProject = {
    ...project,
    rods: newRods,
    mailboxRodChosen: action.mailboxRodChosen,
  };

  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : p);

  return {
    ...state,
    projects: newProjects,
  };
};

const setMailboxPlacement = (state, action) => {
  const project = state.projects.find((project) => project.id === action.projectId);

  const newProject = {
    ...project,
    mailboxPlacement: action.mailboxPlacement,
  };

  const newProjects = state.projects.map((p) => p.id === newProject.id ? newProject : p);

  return {
    ...state,
    projects: newProjects,
  };
};

const app = (state = appInitialState, action) => {
  switch (action.type) {
    case EXAMPLE_PROJECT_WAS_CHOSEN:
      return setExampleProject(state, action);
    case ADD_PROJECT:
      return addProject(state, action.project);
    case SET_PROJECTS:
      return setProjects(state, action.projects);
    case UPDATE_PROJECT:
      return updateProject(state, action.project);
    case PROJECT_WAS_COPIED:
      return projectWasCopied(state, action.updatedProject);
    case CLOSED_MODAL_COPIED_PROJECT:
      return setCloseModalAboutCopiedProject(state, action);
    case REMOVE_PROJECT:
      return removeProject(state, action.projectId);
    case GATE_TILTING_MACHINE_CHECKED:
      return addMachineProfileToProject(state, action);
    case MACHINE_MODAL_IS_SHOWING:
      return { ...state, machineModalIsShowing: action.machineModalIsShowing };
    case ROD_FOR_MACHINE_CHOSEN:
      return setRodForMachine(state, action);
    case MAILBOX_CHECKED:
      return addMailboxToProject(state, action);
    case MAILBOX_MODAL_IS_SHOWING:
      return { ...state, mailboxModalIsShowing: action.mailboxModalIsShowing };
    case ROD_FOR_MAILBOX_CHOSEN:
      return setRodForMailbox(state, action);
    case MAILBOX_PLACEMENT_CHOSEN:
      return setMailboxPlacement(state, action);
    case WIDTH_MODAL_IS_SHOWING:
      return { ...state, widthModalIsShowing: action.widthModalIsShowing };
    case SET_CHOSEN_OPTION:
      return { ...state, chosenOption: action.chosenOption };
    case SET_CURRENT_PROJECT_ID:
      return {
        ...state,
        prevProjectId: state.currentProjectId,
        currentProjectId: action.projectId
      };
    case SET_EXAMPLE_MODEL:
      return { ...state, exampleModel: action.exampleModel };
    case SET_ALERT:
      return { ...state, alert: action.alert };
    case SET_PROJECT_VISUALISATION:
      return { ...state, projectVisualisation: action.projectVisualisation };
    case SET_MENU:
      return { ...state, menu: action.menu };
    case SET_SHOW_FINISH_PROJECT_FORM:
      return { ...state, showFinishProjectForm: action.showFinishProjectForm };
    case SET_PDF_SHOW_WIDTH:
      return {
        ...state,
        pdf: { ...state.pdf, showWidth: action.showWidth }
      };
    case SET_PDF_SHOW_MODAL:
      return {
        ...state,
        pdf: { ...state.pdf, showModal: action.showModal }
      };
    default:
      return state
  }
};

const appReducer = combineReducers({
  app,
});

export default appReducer;
