import {connect} from 'react-redux';
import {setProjectVisualisation} from '../../actions';
import ProjectVisualisation from '../../components/ProjectVisualisation/ProjectVisualisation';

const mapStateToProps = (state) => ({
  projectVisualisation: state.app.projectVisualisation,
  projects: state.app.projects,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectVisualisation: (projectVisualisation) => dispatch(setProjectVisualisation(projectVisualisation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectVisualisation);
