import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { MACHINE_MODAL_IS_SHOWING } from '../actions';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  align-items: baseline;
`;

export const ModalTitle = styled.h1`
  font-weight: lighter;
`;

export const ModalBody = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ModalSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalSliderBtn = styled.button`
  border-radius: 50%;
  border: none;
  width: 28px;
  height: 28px;
  padding: 0.15rem 0.15rem;
  
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ModalActiveSlideContainer = styled.div`
  margin-bottom: 2rem;
  text-align: justify;
  height: 500px;
  width: 650px;
`;

export const Img = styled.img`
  width: 650px;
  height: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
`;

export const ModalBtn = styled.button`
  border-radius: 22px;
  padding: 0.5rem 2rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
`;

const slides = [
  {
    image: "/img/machine/gateTiltingMachine1.jpg",
    text: "Wzmocnienie do automatyki stosuje się zwykle na wysokości ok. 700mm licząc od dolnej ramy " +
      "bramy uchylnej."
  },
  {
    image: "/img/machine/gateTiltingMachine2.jpg",
    text: "Zanim wykonasz i prześlesz projekt bramy uchylnej ze wzmocnieniem do automatyki," +
      " zapoznaj się z instrukcją producenta automatu i sprawdź, na jakiej wysokości powinieneś" +
      " zamontować ramię automatu bramowego.",
  },
  {
    image: "/img/machine/gateTiltingMachine3.jpg",
    text: "Nieprawidłowe umieszczenie siłowników do automatu bramowego może skutkować nieprawidłową pracą " +
      "lub awarią automatu.",
  },
  {
    image: "/img/machine/gateTiltingMachine4.jpg",
    text: <span>
      Pamiętaj, że projekt bramy uchylnej musi zawierać minimum jeden profil 80 mm. Profil ten stanowi element konstrukcji bramy i nie można z niego zrezygnować.
      <br/>
      Profil 80 mm może zostać oznaczony jako "Wzmocnienie do automatyki". Każdemu z profili 80 mm towarzyszy charakterystyczna
      {" "}
      <b>czerwona ikona na żółtym tle</b>
      {". "}
      W ramach projektu bramy uchylnej możliwe jest zaznaczenie tylko jednego profilu 80 mm z opcją "wzmocnienie do automatyki".</span>
  }
];

const MachineModal = () => {
  const dispatch = useDispatch();

  const machineModalIsShowing = useSelector(state => state.app.machineModalIsShowing) || null;

  const [activeSlide, setActiveSlide] = useState(slides[0]);

  const hideModal = () => {
    dispatch({ type: MACHINE_MODAL_IS_SHOWING, machineModalIsShowing: false })
  };

  const goToPrevSlide = () => {
    if (activeSlide === slides[0]) {
      setActiveSlide(slides[slides.length - 1]);
    } else {
      const currentSlideIndex = slides.findIndex((slide) => slide === activeSlide);
      setActiveSlide(slides[currentSlideIndex - 1]);
    }
  };

  const goToNextSlide = () => {
    if (activeSlide === slides[slides.length - 1]) {
      setActiveSlide(slides[0]);
    } else {
      const currentSlideIndex = slides.findIndex((slide) => slide === activeSlide);
      setActiveSlide(slides[currentSlideIndex + 1]);
    }
  };

  const showActiveSlide = () => {
    return (
      <ModalActiveSlideContainer>
        <Img alt="" src={activeSlide.image}/>
        {activeSlide.text}
      </ModalActiveSlideContainer>
    )
  };

  if (machineModalIsShowing === true) {
    return (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <ModalHeader>
              <ModalTitle>UWAGA</ModalTitle>
            </ModalHeader>
            <ModalBody className="modal-body">
              <ModalSliderContainer>
                <ModalSliderBtn className="button btn-secondary" onClick={goToPrevSlide}>
                <FontAwesomeIcon icon={faAngleLeft} style={{ color: '#ffffff' }}/>
                </ModalSliderBtn>
              {showActiveSlide()}
                <ModalSliderBtn className="button btn-secondary" onClick={goToNextSlide}>
                <FontAwesomeIcon icon={faAngleRight} style={{ color: '#ffffff' }}/>
                </ModalSliderBtn>
              </ModalSliderContainer>
              <div>
                <ModalBtn
                  className="btn btn-success"
                  type="button"
                  onClick={hideModal}
                >
                  <span>Rozumiem</span>
                </ModalBtn>
              </div>
            </ModalBody>
          </div>
        </div>
      </div>
    )
  }
  return null;
};

export default MachineModal;
