import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DISAPPEARING_TIMEOUT } from '../App';
import { calcProjectHeight } from '../Rod/RodSpace';
import changeRodsForSpanRequirements from '../../changeRodsForSpanRequirements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import MenuInput from './MenuInput';
import {
  InputContainer, MenuInputBtn,
  MenuInputBtnContainer,
  MenuInputLabel,
  MenuInputsContainer, MenuInputSmall,
  MenuInputText,
} from './MenuHeight';
import { FENCE_TYPE_SPAN } from 'fenceTypesConstants';

const DefaultSpace = ({ project, updateProject }) => {
  const [info, setInfo] = useState(null);
  const [timer, setTimer] = useState(null);
  const [defaultSpaceTmp, setDefaultSpaceTmp] = useState(project.defaultSpace || '');

  useEffect(() => setDefaultSpaceTmp(project.defaultSpace), [project.defaultSpace]);

  const change = (newVal, clickThroughButton) => {
    const min = 10;
    const max = 2000;
    clearTimeout(timer);

    setInfo(null);
    newVal = parseInt(newVal) || '';
    setDefaultSpaceTmp(newVal);

    let newProject = {
      ...project,
      defaultSpace: newVal,
      additionalElementTop: {
        ...project.additionalElementTop,
        space: newVal,
      }
    }

    if (newProject.type === FENCE_TYPE_SPAN) {
      newProject = {
        ...newProject,
        additionalElementTop: {
          ...newProject.additionalElementTop,
          space: 0,
        }
      }
    }

    if (project && project.rods) {
      newProject.rods = project.rods.map((rod) => {
        if (project.defaultSpace !== rod.space) {
          return {
            ...rod,
          }
        } else if (rod.space === 0) {
          return {
            ...rod,
            space: newProject.defaultSpace,
          }
        } else {
          return {
            ...rod,
            space: newProject.defaultSpace,
          }
        }
      });
      if (newProject.type === FENCE_TYPE_SPAN) {
        newProject = changeRodsForSpanRequirements(newProject);
      }
    }
    const heightAfterUpdate = calcProjectHeight(newProject);

    let info = null;
    if (!project) {
      info = 'Wybierz rodzaj projektu.';
    } else if (typeof newVal === 'number') {
      if (newVal < min) {
        info = `Minimalny odstęp to ${min} mm.`;
      } else if (newVal > max) {
        info = `Maksymalny odstęp to ${max} mm.`
      } else if (heightAfterUpdate > project.heightExpected && newVal > project.defaultSpace) {
        if (project.heightExpected - project.height > 0) {
          info = `Zbyt duży odstęp. Pozostało Ci ${project.heightExpected - project.height} mm.`;
        } else {
          info = 'Nie możesz zmienić odstępu. Projekt jest za wysoki.';
        }
      }
    }

    const setValues = () => {
      if (info) {
        setInfo(info);
        setDefaultSpaceTmp(project.defaultSpace);
      } else {
        if (newVal === '') {
          updateProject({
            ...project,
            defaultSpace: min,
          });
          setInfo('Wpisz liczbę.');
        } else {
          updateProject(newProject);
          setInfo('Zapisano.');
          setTimeout(() => setInfo(null), DISAPPEARING_TIMEOUT);
        }
      }
    };

    if (clickThroughButton) {
      setValues();
    } else {
      setTimer(setTimeout(() => {
        setValues();
      }, 700));
    }
  };

  return (
    <MenuInputsContainer className="form-group">
      <MenuInputLabel htmlFor="defaultSpace">Domyślny odstęp</MenuInputLabel>
      <InputContainer>
        <MenuInputText>mm</MenuInputText>
        <MenuInput
          id="defaultSpace"
          step={1}
          change={(e) => change(e.target.value, false)}
          value={defaultSpaceTmp}
          sentry="default-space"
        />
        <MenuInputBtnContainer>
          <MenuInputBtn style={{ marginRight: '0.5rem' }}
                        onClick={() => change(parseInt(project.defaultSpace + 1), true)}>
            <FontAwesomeIcon icon={faPlus} style={{ color: '#ffffff' }}/>
          </MenuInputBtn>
          <MenuInputBtn onClick={() => change(parseInt(project.defaultSpace - 1), true)}>
            <FontAwesomeIcon icon={faMinus} style={{ color: '#ffffff' }}/>
          </MenuInputBtn>
        </MenuInputBtnContainer>
      </InputContainer>
      <MenuInputSmall className="form-text">{info}</MenuInputSmall>
    </MenuInputsContainer>
  );
};

DefaultSpace.propTypes = {
  project: PropTypes.object,
  updateProject: PropTypes.func.isRequired,
};

export default DefaultSpace;
