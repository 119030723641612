import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Img,
  ModalActiveSlideContainer,
  ModalBody,
  ModalBtn,
  ModalHeader,
  ModalSliderBtn,
  ModalSliderContainer,
  ModalTitle
} from './MachineModal';
import { WIDTH_MODAL_IS_SHOWING } from '../actions';

const slides = [
  {
    image: '/img/width/span.jpg',
    text: 'Wszystkie projekty modelu Diverso zawierające kształtownik o przekroju 20x20 mm zostają dodatkowo wzmocnione płaskownikiem umiejscowionym na środku.'
  },
  {
    image: '/img/width/gateTilting.jpg',
    text: 'Płaskownik jest stosowany od szerokości 1500 mm i dotyczy zarówno przęseł jak ' +
      'i wypełnienia w bramach samonośnych i uchylnych oraz jednoskrzydłowych i łamanych.'
  },
  {
    image: '/img/width/gateSelfSupporting.jpg',
    text: <span>
      Więcej informacji na temat Diverso znajdziesz w katalogu technicznym Konsport w serwisie
      {" "}
      <a href="https://www.info.konsport.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontWeight: "bold"}}>info.konsport.com</a>
      {" "}
      w rozdziale:
      {" "}
      <b>DIVERSO</b> oraz <b>DIVERSO STANDARD</b>.
    </span>
  }
];

const WidthModal = () => {
  const dispatch = useDispatch();

  const widthModalIsShowing = useSelector(state => state.app.widthModalIsShowing) || null;

  const [activeSlide, setActiveSlide] = useState(slides[0]);

  const hideModal = () => {
    dispatch({ type: WIDTH_MODAL_IS_SHOWING, widthModalIsShowing: false });
  };

  const goToPrevSlide = () => {
    if (activeSlide === slides[0]) {
      setActiveSlide(slides[slides.length - 1]);
    } else {
      const currentSlideIndex = slides.findIndex((slide) => slide === activeSlide);
      setActiveSlide(slides[currentSlideIndex - 1]);
    }
  };

  const goToNextSlide = () => {
    if (activeSlide === slides[slides.length - 1]) {
      setActiveSlide(slides[0]);
    } else {
      const currentSlideIndex = slides.findIndex((slide) => slide === activeSlide);
      setActiveSlide(slides[currentSlideIndex + 1]);
    }
  };

  const showActiveSlide = () => {
    return (
      <ModalActiveSlideContainer>
        <Img alt="" src={activeSlide.image}/>
        {activeSlide.text}
      </ModalActiveSlideContainer>
    );
  };

  if (widthModalIsShowing) {
    return (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <ModalHeader>
              <ModalTitle>UWAGA</ModalTitle>
            </ModalHeader>
            <ModalBody className="modal-body">
              <ModalSliderContainer>
                <ModalSliderBtn className="button btn-secondary" onClick={goToPrevSlide}>
                  <FontAwesomeIcon icon={faAngleLeft} style={{ color: '#ffffff' }}/>
                </ModalSliderBtn>
                {showActiveSlide()}
                <ModalSliderBtn className="button btn-secondary" onClick={goToNextSlide}>
                  <FontAwesomeIcon icon={faAngleRight} style={{ color: '#ffffff' }}/>
                </ModalSliderBtn>
              </ModalSliderContainer>
              <div>
                <ModalBtn
                  className="btn btn-success"
                  type="button"
                  onClick={hideModal}
                >
                  <span>Rozumiem</span>
                </ModalBtn>
              </div>
            </ModalBody>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default WidthModal;
