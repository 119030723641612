import React from 'react';
import PropTypes from 'prop-types';
import DefaultSpace from './DefaultSpace';
import DesignNextFence from './DesignNextFence';
import ProjectType from '../../containers/Menu/ProjectType';
import MenuWidth from './MenuWidth';
import MenuHeight from './MenuHeight';
import PdfBtn from '../Pdf/PdfBtn';
import FinishProject from '../FinishProject/FinishProject';
import VisualisationBtn from './VisualisationBtn';
import styled from 'styled-components';
import { FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY, FENCE_TYPE_SPAN } from 'fenceTypesConstants';
import { useSelector } from 'react-redux';
import Mailbox from './Mailbox';
import logo from 'assets/logo.svg';

const MenuContainer = styled.nav`
  position: fixed;
  height: 100%;
  width: 450px;
  background: #2d3234;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
`;

const HeaderImg = styled.img`
  width: 200px;
  margin: 4rem 4rem 3rem;
`;

const MenuParams = styled.div`
  margin-left: 4rem;
  margin-right: 4rem;
`;

const MenuHeightInfoDiv = styled.div`
  position: relative;
`;

const ProjectActions = styled.div`
  position: relative;
  margin: 1rem 4rem 0;

  & > div {
    margin-bottom: 0.5rem;
  }
`;

const MenuHeightInfo = styled.p`
  color: #c0c2c2;
`;

const MenuHeightBold = styled.span`
  ${props => props.showError ? 'color: #ff0000' : 'color: #ffffff'};
  font-weight: bold;
`;

const Footer = styled.div`
  color: #8e9192;
  font-size: 0.9rem;
  margin: 4rem;
`;

const CopyP = styled.p`
  margin-top: 2rem;
`;

const ProjectNotReadySpan = styled.span`
  display: block;
  margin-left: 4rem;
  width: 68%;
  text-align: justify;
  color: #c0c2c2;
`;

export const leftHeight = (project) => {
  if (project && project.height && project.heightExpected) {
    return project.heightExpected - project.height;
  } else {
    return null;
  }
};

const Menu = (
  {
    canStartDesigning,
    menu,
    updateProject,
    setOpenTooltip
  }
) => {
  const projects = useSelector((state) => state.app.projects);
  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));

  const canTakeNextActions = (project) => {
    if (project && project.type === FENCE_TYPE_GATE_TILTING && !project.rods.some((rod) => rod.rodForMachine === true)) {
      return false;
    }
    return !(project && project.height !== project.heightExpected);
  };

  return (
    <MenuContainer>
      <div className="row">
        <div className="col">
          <div>
            <HeaderImg src={logo} alt=""/>
          </div>
          <MenuParams>
            <ProjectType project={project} setOpenTooltip={setOpenTooltip}/>
            {project &&
            (<>
              <MenuWidth updateProject={updateProject} setOpenTooltip={setOpenTooltip}/>
              <MenuHeight updateProject={updateProject} menu={menu}/>
              <MenuHeightInfoDiv className={'form-group'}>
                <MenuHeightInfo>
                  Wykorzystano <MenuHeightBold
                  showError={project.heightExpected - leftHeight(project) > project.heightExpected}>{project && project.heightExpected - leftHeight(project)} mm</MenuHeightBold> / {project && project.heightExpected} mm
                </MenuHeightInfo>
              </MenuHeightInfoDiv>
              <DefaultSpace project={project} updateProject={updateProject}/>
              {
                (project.type === FENCE_TYPE_SPAN || project.type === FENCE_TYPE_GATEWAY)
                && <Mailbox project={project}/>
              }
              {/*{project.type === FENCE_TYPE_GATE_TILTING && <GateTiltingMachine project={project}/>}*/}
            </>)}
          </MenuParams>
          {project && canTakeNextActions(project) ?
            (<ProjectActions className="inner inner-bottom">
              <DesignNextFence/>
              {canStartDesigning &&
              <>
                <VisualisationBtn/>
                <PdfBtn projects={projects} project={project}/>
                <FinishProject/>
              </>
              }
            </ProjectActions>) :
            (
              <ProjectNotReadySpan>
                Zakończenie i zapisanie projektu będzie możliwe po zakończeniu projektu.
                Zadbaj o wyczerpanie dostępnej wysokości.
                W przypadku bram uchylnych wskaż profil do automatyki bramowej.
                Więcej informacji na temat automatyki bramowej znajdziesz również
                w
                {" "}
                <a href="https://www.info.konsport.com/" target="_blank" rel="noopener noreferrer"
                   style={{ fontWeight: 'bold', color: '#c0c2c2' }}>
                  katalogu technicznym Konsport
                </a>
                {" "}
                w rozdziale <b>DIVERSO</b> oraz <b>DIVERSO STANDARD</b>
                {" "}
                w sekcji <b style={{ color: '#ff0000' }}>Dodatkowy poziom</b>.
              </ProjectNotReadySpan>
            )
          }
          <Footer>
            <span>
              Jana Kilińskiego 75
              <br/>
              95-083 Kazimierz
              <br/>
              +48 43 677 50 63
              <br/>
              biuro@konsport.com.pl
            </span>
            <CopyP>
              &copy; Konsport
            </CopyP>
          </Footer>
        </div>
      </div>
    </MenuContainer>
  );
};

Menu.propTypes = {
  project: PropTypes.object,
  projects: PropTypes.array.isRequired,
  canStartDesigning: PropTypes.bool.isRequired,
  menu: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
  setProjectVisualisation: PropTypes.func.isRequired
};

export default Menu;
