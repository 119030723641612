import React from 'react';
import PropTypes from 'prop-types';
import {MIN_SCALE} from './ProjectVisualisation';

const ProjectVisualisationBody = ({project, projectVisualisation}) => {
  const rods = project.rods.map((rod) => {
    const sizeHeight = rod.size / projectVisualisation.scale;
    const sizeClassSmall = sizeHeight < 14 ? 'projectVisualisationRod__small' : '';
    const sizeContentHiddenClass = projectVisualisation.scale !== MIN_SCALE ? 'projectVisualisationRod__contentHidden' : '';

    const spaceHeight = rod.space / projectVisualisation.scale;
    const spaceClassSmall = sizeHeight < 14 ? 'projectVisualisationSpace__small' : '';
    const spaceContentHiddenClass = projectVisualisation.scale !== MIN_SCALE || rod.space < 12 ? 'projectVisualisationSpace__contentHidden' : '';

    return (
      <div key={rod.id}>
        <div
          className={`projectVisualisationRod ${sizeClassSmall} ${sizeContentHiddenClass}`}
          style={{height: `${sizeHeight}px`}}
        >
          <span>Kształtownik {rod.size} mm</span>
        </div>
        <div
          className={`projectVisualisationSpace ${spaceClassSmall} ${spaceContentHiddenClass}`}
          style={{height: `${spaceHeight}px`}}
        >
          <span>Odstęp {rod.space} mm</span>
        </div>
      </div>
    );
  });

  const AdditionalElementTop = () => {
    if (!project.additionalElementTop || project.additionalElementTop.space === 0) {
      return null;
    }

    const spaceHeight = project.additionalElementTop.space / projectVisualisation.scale;
    const spaceClassSmall = spaceHeight < 14 ? 'projectVisualisationAdditionalElementTop__small' : '';
    const spaceContentHiddenClass = projectVisualisation.scale !== MIN_SCALE || project.additionalElementTop.space < 12 ? 'projectVisualisationAdditionalElementTop__contentHidden' : '';

    return (
      <div
        className={`projectVisualisationAdditionalElementTop ${spaceClassSmall} ${spaceContentHiddenClass}`}
        style={{height: `${spaceHeight}px`}}
      >
        <span>Odstęp {project.additionalElementTop.space} mm</span>
      </div>
    );
  };

  return (
    <div className={`projectVisualisationBody projectVisualisationBody__${project.type}`}>
      <AdditionalElementTop/>
      {rods}
    </div>
  );
};

ProjectVisualisationBody.propTypes = {
  project: PropTypes.object.isRequired,
  projectVisualisation: PropTypes.object.isRequired,
};

export default ProjectVisualisationBody;
