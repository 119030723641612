import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { SET_SHOW_FINISH_PROJECT_FORM } from '../../actions';
import styled from 'styled-components';

const Button = styled.button`
  border-radius: 40px;
  padding: 0.5rem 2.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
`;

const FinishProject = () => {
  const dispatch = useDispatch();

  const click = (e) => {
    e.preventDefault();

    dispatch({ type: SET_SHOW_FINISH_PROJECT_FORM, showFinishProjectForm: true });
  };

  return (
    <div>
      <Button
        className={'btn btn-danger btn-icon-left'}
        type={'button'}
        data-sentry="finish-project"
        onClick={click}
      >
        <FontAwesomeIcon icon={faCheck}/>
        <span>Zakończ projekt</span>
      </Button>
    </div>
  );
};

export default FinishProject;
