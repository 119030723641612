export const SecondCSpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const SecondCGateway = {
  additionalElementTop: {
    space: 42,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    }
  ],
};

export const SecondCGateTilting = {
  additionalElementTop: {
    space: 41,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    }
  ]
};

export const SecondCGateSelfSupporting = {
  additionalElementTop: {
    space: 41,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    }
  ],
};
