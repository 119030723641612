export const ThirdBSpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 72,
    },
    {
      size: 20,
      space: 72,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const ThirdBGateway = {
  additionalElementTop: {
    space: 72,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 72,
    }
  ],
};

export const ThirdBGateTilting = {
  additionalElementTop: {
    space: 72,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 73,
    },
    {
      size: 80,
      space: 73,
    },
    {
      size: 20,
      space: 72,
    }
  ],
};

export const ThirdBGateSelfSupporting = {
  additionalElementTop: {
    space: 72,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 20,
      space: 66,
    },
    {
      size: 80,
      space: 66,
    },
    {
      size: 20,
      space: 66,
    },
    {
      size: 80,
      space: 66,
    },
    {
      size: 20,
      space: 66,
    },
    {
      size: 80,
      space: 67,
    },
    {
      size: 20,
      space: 67,
    },
    {
      size: 80,
      space: 67,
    },
    {
      size: 20,
      space: 67,
    },
    {
      size: 80,
      space: 67,
    },
    {
      size: 20,
      space: 67,
    },
    {
      size: 80,
      space: 67,
    },
    {
      size: 20,
      space: 67,
    }
  ],
};
