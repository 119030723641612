export const SET_CHOSEN_OPTION = 'SET_CHOSEN_OPTION';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';

export const PROJECT_WAS_COPIED = 'PROJECT_WAS_COPIED';
export const CLOSED_MODAL_COPIED_PROJECT = 'CLOSED_MODAL_COPIED_PROJECT';

export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID';

export const SET_ALERT = 'SET_ALERT';

export const SET_EXAMPLE_MODEL = 'SET_EXAMPLE_MODEL';

export const SET_MENU = 'SET_MENU';

export const SET_PROJECTS = 'SET_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const EXAMPLE_PROJECT_WAS_CHOSEN = 'EXAMPLE_PROJECT_WAS_CHOSEN';

export const addProject = (project) => ({type: ADD_PROJECT, project: project});

export const updateProject = (project) => ({type: UPDATE_PROJECT, project: project});

export const setCurrentProjectId = (projectId) => ({type: SET_CURRENT_PROJECT_ID, projectId: projectId});

export const setAlert = (alert) => ({type: SET_ALERT, alert: alert});

export const SET_PROJECT_VISUALISATION = 'SET_PROJECT_VISUALISATION';

export const setProjectVisualisation = (projectVisualisation) => ({type: SET_PROJECT_VISUALISATION, projectVisualisation: projectVisualisation});

export const setExampleModel = (exampleModel) => ({type: SET_EXAMPLE_MODEL, exampleModel: exampleModel});

export const setMenu = (menu) => ({type: SET_MENU, menu: menu});

export const SET_SHOW_FINISH_PROJECT_FORM = 'SET_SHOW_FINISH_PROJECT_FORM';

export const SET_PDF_SHOW_WIDTH = 'SET_PDF_SHOW_WIDTH';
export const SET_PDF_SHOW_MODAL = 'SET_PDF_SHOW_MODAL';

export const GATE_TILTING_MACHINE_CHECKED = 'GATE_TILTING_MACHINE_CHECKED';
export const MACHINE_MODAL_IS_SHOWING = 'MACHINE_MODAL_IS_SHOWING';
export const ROD_FOR_MACHINE_CHOSEN = 'ROD_FOR_MACHINE_CHOSEN';

export const MAILBOX_CHECKED = 'MAILBOX_CHECKED';
export const MAILBOX_MODAL_IS_SHOWING = 'MAILBOX_MODAL_IS_SHOWING';
export const ROD_FOR_MAILBOX_CHOSEN = 'ROD_FOR_MAILBOX_CHOSEN';
export const MAILBOX_PLACEMENT_CHOSEN = 'MAILBOX_PLACEMENT_CHOSEN';

export const WIDTH_MODAL_IS_SHOWING = 'WIDTH_MODAL_IS_SHOWING'
