export const FourthCSpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const FourthCGateway = {
  additionalElementTop: {
    space: 11,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    }
  ],
};

export const FourthCGateTilting = {
  additionalElementTop: {
    space: 11,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 11,
    },
    {
      size: 20,
      space: 11,
    },
    {
      size: 80,
      space: 11,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    }
  ],
};

export const FourthCGateSelfSupporting = {
  additionalElementTop: {
    space: 11,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    }
  ],
};
