import React, { useState } from 'react';

import {FormikProvider, useFormik} from 'formik';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Menu from '../containers/Menu/Menu';
import RodsCollection from '../containers/Rod/RodsCollection';
import ProjectBoard from '../containers/ProjectBoard';
import Alert from '../containers/Alert';
import ProjectVisualisation from '../containers/ProjectVisualisation/ProjectVisualisation';
import ShouldLoadExampleAlert from '../containers/ShouldLoadExampleModelAlert';
import ProjectBoardInfo from './ProjectBoardInfo';
import FinishProjectForm from './FinishProject/FinishProjectForm';
import LoadProjectFromUrl, { findProjectIdInUrl } from './LoadProjectFromUrl/LoadProjectFromUrl';
import LoadProjectFromUrlAlert from './LoadProjectFromUrl/LoadProjectFromUrlAlert';
import Header from './Header';
import PdfModal from './Pdf/Modal/Modal';
import GettingStarted from './GettingStarted/GettingStarted';
import { useSelector } from 'react-redux';
import CopyButton from './FillParametersHelper/CopyButton';
import MachineModal from './MachineModal';
import CopiedProjectModal from './FillParametersHelper/CopiedProjectModal';
import MailboxModal from './MailboxModal';
import { FENCE_TYPE_GATE_TILTING } from '../fenceTypesConstants';
import WidthModal from './WidthModal';

export const DISAPPEARING_TIMEOUT = 2000;

const Container = styled.div`
  ${props => props.openTooltip && 'background: #000000 !important; width: 100vw; height: 100vw; opacity: 0.25;'}
`;

export const API_URL = 'https://api.my.konsport.com/diverso';
export const PDF_CSS_URL = 'https://kns-diverso.s3.eu-central-1.amazonaws.com/pdf.min.css';

const App = () => {
  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));
  const chosenOption = useSelector(state => state.app.chosenOption) || null;
  const canStartDesigning = useSelector(state => state.app.canStartDesigning);
  const showFinishProjectForm = useSelector(state => state.app.showFinishProjectForm);
  const [openTooltip, setOpenTooltip] = useState(false);
  const loadingProjectId = findProjectIdInUrl() || null;
  const formik = useFormik({
    initialValues: {
      projects: [],
    },
  });
  return (
    <FormikProvider value={formik}>
      {!chosenOption && <GettingStarted/>}
      {(chosenOption || canStartDesigning) && <Menu project={project} setOpenTooltip={setOpenTooltip}/>}
      {(chosenOption || canStartDesigning) &&
      <Container openTooltip={openTooltip}>
        <Header project={project} openTooltip={openTooltip}/>
      </Container>
      }
      {loadingProjectId && <LoadProjectFromUrl/>}
      {project &&
      <main>
        <Container>
          <CopyButton/>
          {canStartDesigning && <ProjectBoardInfo project={project}/>}
          {canStartDesigning && <ProjectBoard project={project}/>}
        </Container>
        {canStartDesigning && <RodsCollection project={project} setOpenTooltip={setOpenTooltip}/>}
      </main>
      }
      <Alert/>
      <LoadProjectFromUrlAlert/>
      <ShouldLoadExampleAlert/>
      {showFinishProjectForm && <FinishProjectForm/>}
      <ProjectVisualisation project={project}/>
      {project && <CopiedProjectModal project={project}/>}
      {project && project.type === FENCE_TYPE_GATE_TILTING && <MachineModal project={project}/>}
      {project && <WidthModal/>}
      <MailboxModal project={project}/>
      <PdfModal/>
    </FormikProvider>
  );
};

export default App;
