import React from 'react';
import PropTypes from 'prop-types';
import ProjectVisualisationBody from './ProjectVisualisationBody';
import './ProjectVisualisationGateSelfSupporting.css';

const ProjectVisualisationGateSelfSupporting = ({project, projectVisualisation, containerStyle}) => {
  return (
    <div className={'projectVisualisation projectVisualisationGateSelfSupporting'} style={containerStyle} data-scale={projectVisualisation.scale}>
      <div className={'projectVisualisationGateSelfSupporting__spanTop'}></div>
      <div className={'projectVisualisationGateSelfSupporting__span'}></div>
      <div className={'projectVisualisationGateSelfSupporting__spanBottom'}></div>
      <div className={'projectVisualisationBody projectVisualisationBody__gateSelfSupportingTop'}></div>
      <ProjectVisualisationBody project={project} projectVisualisation={projectVisualisation}/>
      <div className={'projectVisualisationBody projectVisualisationBody__gateSelfSupportingBottom'}></div>
    </div>
  );
};

ProjectVisualisationGateSelfSupporting.propTypes = {
  project: PropTypes.object.isRequired,
  projectVisualisation: PropTypes.object.isRequired,
  containerStyle: PropTypes.object.isRequired,
};

export default ProjectVisualisationGateSelfSupporting;
