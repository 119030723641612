import React from 'react';
import PropTypes from 'prop-types';
import RodSpace from './RodSpace';
import { v4 as uuid_v4 } from 'uuid';
import { FENCE_TYPE_SPAN } from 'fenceTypesConstants';
import styled from 'styled-components';

const AdditionalElementTopDiv = styled.div`
  background: #bfbfbf;
  border-radius: 50px;
  color: #000000;
  width: 100%;
  padding: 1rem 2rem 0.5rem 2rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;


const AdditionalElementTop = ({ project, updateProject, setAlert }) => {
  if (project.type === FENCE_TYPE_SPAN) {
    return null;
  }

  const space = project.additionalElementTop ? project.additionalElementTop.space : project.rods[0].space;

  return (
    <>
      <AdditionalElementTopDiv id={'additionalElementTop'} className={'rod'}>
        <span>Profil <b>{project.additionalElementTop.size}</b> mm (RAMA GÓRNA)</span>
        <span>Element wymagany</span>
      </AdditionalElementTopDiv>
      <RodSpace
        id={uuid_v4()}
        space={space}
        project={project}
        setAlert={setAlert}
        updateProject={updateProject}
        isAdditionalElementTop={true}
      />
    </>
  );
};

AdditionalElementTop.propTypes = {
  project: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default AdditionalElementTop;
