import {FENCE_TYPE_GATE_SELF_SUPPORTING, FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY, FENCE_TYPE_SPAN} from 'fenceTypesConstants';
import {FirstAGateSelfSupporting, FirstAGateTilting, FirstAGateway, FirstASpan} from './FirstA';
import {FirstBGateSelfSupporting, FirstBGateTilting, FirstBGateway, FirstBSpan} from './FirstB';
import {FirstCGateSelfSupporting, FirstCGateTilting, FirstCGateway, FirstCSpan} from './FirstC';
import {SecondAGateSelfSupporting, SecondAGateTilting, SecondAGateway, SecondASpan} from './SecondA';
import {SecondBGateSelfSupporting, SecondBGateTilting, SecondBGateway, SecondBSpan} from './SecondB';
import {SecondCGateSelfSupporting, SecondCGateTilting, SecondCGateway, SecondCSpan} from './SecondC';
import { ThirdAGateSelfSupporting, ThirdAGateTilting, ThirdAGateway, ThirdASpan } from './ThirdA';
import { ThirdBGateSelfSupporting, ThirdBGateTilting, ThirdBGateway, ThirdBSpan } from './ThirdB';
import { ThirdCGateSelfSupporting, ThirdCGateTilting, ThirdCGateway, ThirdCSpan } from './ThirdC';
import { FourthAGateSelfSupporting, FourthAGateTilting, FourthAGateway, FourthASpan } from './FourthA';
import { FourthBGateSelfSupporting, FourthBGateTilting, FourthBGateway, FourthBSpan } from './FourthB';
import { FourthCGateSelfSupporting, FourthCGateTilting, FourthCGateway, FourthCSpan } from './FourthC';

export const exampleModelsArray = [
  [
    {
      height: 1500,
      width: 2500,
      defaultSpace: 12,
      [FENCE_TYPE_SPAN]: {
        ...FirstASpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...FirstAGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...FirstAGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...FirstAGateSelfSupporting,
      },
    },
    {
      height: 1800,
      width: 2500,
      defaultSpace: 16,
      [FENCE_TYPE_SPAN]: {
        ...FirstBSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...FirstBGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...FirstBGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...FirstBGateSelfSupporting,
      },
    },
    {
      height: 2000,
      width: 2500,
      defaultSpace: 16,
      [FENCE_TYPE_SPAN]: {
        ...FirstCSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...FirstCGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...FirstCGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...FirstCGateSelfSupporting,
      },
    },
  ],
  [
    {
      height: 1500,
      width: 2000,
      defaultSpace: 42,
      [FENCE_TYPE_SPAN]: {
        ...SecondASpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...SecondAGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...SecondAGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...SecondAGateSelfSupporting,
      },
    },
    {
      height: 1800,
      width: 2500,
      defaultSpace: 41,
      [FENCE_TYPE_SPAN]: {
        ...SecondBSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...SecondBGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...SecondBGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...SecondBGateSelfSupporting,
      },
    },
    {
      height: 2000,
      width: 2500,
      defaultSpace: 42,
      [FENCE_TYPE_SPAN]: {
        ...SecondCSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...SecondCGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...SecondCGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...SecondCGateSelfSupporting,
      },
    },
  ],
  [
    {
      height: 1500,
      width: 2500,
      defaultSpace: 68,
      [FENCE_TYPE_SPAN]: {
        ...ThirdASpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...ThirdAGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...ThirdAGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...ThirdAGateSelfSupporting,
      },
    },
    {
      height: 1800,
      width: 2500,
      defaultSpace: 72,
      [FENCE_TYPE_SPAN]: {
        ...ThirdBSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...ThirdBGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...ThirdBGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...ThirdBGateSelfSupporting,
      },
    },
    {
      height: 2000,
      width: 2500,
      defaultSpace: 70,
      [FENCE_TYPE_SPAN]: {
        ...ThirdCSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...ThirdCGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...ThirdCGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...ThirdCGateSelfSupporting,
      },
    },
  ],
  [
    {
      height: 1500,
      width: 2500,
      defaultSpace: 12,
      [FENCE_TYPE_SPAN]: {
        ...FourthASpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...FourthAGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...FourthAGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...FourthAGateSelfSupporting,
      },
    },
    {
      height: 1800,
      width: 2500,
      defaultSpace: 10,
      [FENCE_TYPE_SPAN]: {
        ...FourthBSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...FourthBGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...FourthBGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...FourthBGateSelfSupporting,
      },
    },
    {
      height: 2000,
      width: 2500,
      defaultSpace: 11,
      [FENCE_TYPE_SPAN]: {
        ...FourthCSpan,
      },
      [FENCE_TYPE_GATEWAY]: {
        ...FourthCGateway,
      },
      [FENCE_TYPE_GATE_TILTING]: {
        ...FourthCGateTilting,
      },
      [FENCE_TYPE_GATE_SELF_SUPPORTING]: {
        ...FourthCGateSelfSupporting,
      },
    },
  ],
];

export const getExampleModel = ({height, width, defaultSpace}) => {
  const exampleModelArray = exampleModelsArray.find((models) => models.find((model) =>
    model.width === width && model.height === height && model.defaultSpace === defaultSpace));
  const exampleModel = exampleModelArray.filter((model) => model !== undefined)[0];

  if (!exampleModel) {
    throw new Error();
  }

  return exampleModel;
};
