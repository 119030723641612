export const SecondASpan = {
  additionalElementTop: null,
  rods: [
    {
      size: 80,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 80,
      space: 0,
    }
  ],
  additionalElementBottom: null,
};

export const SecondAGateway = {
  additionalElementTop: {
    space: 41,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    }
  ]
};

export const SecondAGateTilting = {
  additionalElementTop: {
    space: 41,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    }
  ]
};

export const SecondAGateSelfSupporting = {
  additionalElementTop: {
    space: 41,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 41,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    },
    {
      size: 20,
      space: 42,
    }
  ]
};
