import React from 'react';
import PropTypes from 'prop-types';
import ProjectVisualisationBody from './ProjectVisualisationBody';
import './ProjectVisualisationSpan.css';

const ProjectVisualisationSpan = ({project, projectVisualisation, containerStyle}) => {
  return (
    <div className={'projectVisualisation projectVisualisationSpan'} style={containerStyle} data-scale={projectVisualisation.scale}>
      <div className={'projectVisualisationSpan__spanLeft'}></div>
      <ProjectVisualisationBody project={project} projectVisualisation={projectVisualisation}/>
      <div className={'projectVisualisationSpan__spanRight'}></div>
    </div>
  );
};

ProjectVisualisationSpan.propTypes = {
  project: PropTypes.object.isRequired,
  projectVisualisation: PropTypes.object.isRequired,
  containerStyle: PropTypes.object.isRequired,
};

export default ProjectVisualisationSpan;
