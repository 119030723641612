import React, {useRef} from 'react';
import RodSpace, {calcProjectHeight} from './RodSpace';
import {faEllipsisV, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {useDrag, useDrop} from 'react-dnd';
import update from 'immutability-helper';
import {v4 as uuid_v4} from 'uuid';
import RodSize from './RodSize';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { MAILBOX_PLACEMENT_CHOSEN, ROD_FOR_MACHINE_CHOSEN, ROD_FOR_MAILBOX_CHOSEN } from '../../actions';
import { FENCE_TYPE_GATE_TILTING } from 'fenceTypesConstants';
import {MOVE_TYPE_DROP, MOVE_TYPE_HOVER} from './Rods';
import {
  mailboxPlacements,
  mailboxPlacementInPolish,
} from '../../ProjectConstants';

const RodGroup = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.targetNeighbor && 'margin-bottom: 6rem'};
  ${props => props.dragging && 'opacity: 0'};
`;

const Container = styled.div`
  padding: 1rem 2rem 0rem 2rem;
  display: flex;
  align-items: baseline;
  border-radius: 50px;
  background: #808080;
  position: relative;
  & > div {
    margin-right: 1.5rem;
  }
  
  & > div:last-child {
    margin-right: 1.5rem;
  }
`;

const Index = styled.span`
  color: #ffffff;
  font-weight: bold;
`;

const RodBtn = styled.button`
  border: 1px solid #ffffff;
  border-radius: 50px;
  color: #ffffff;
  background: transparent;
  padding: 0.4rem 1.5rem;
  opacity: 0.75;
  
  &:hover {
    border: 1px solid #000000;
  }
  
  &:focus{
    border: 1px solid #000000;
    box-shadow: none;
    outline: none;
  }
`;

const BtnContainer = styled.div`
  & > button {
    margin-right: 1.5rem;
  }
`;

const MachineInputContainer = styled.div`
  margin-left: -1rem;
  color: #ffffff;
  opacity: 0.75;
  display: flex;
  align-items: center;
`;

export const MailboxSelectContainer = styled.div`
  background: #808080;
`;

export const MailboxSelect = styled.select`
  border-radius: 50px;
  background: #ffffff;
  color: #000000;
  
  &:focus{
    outline: none;
    border: none;
    box-shadow: none;
  }
`;


const MachineCheckboxLabel = styled.label`
  ${props => props.disabled && 'color: #ffffff !important; opacity: 0.5;'}
`;

const Img = styled.img`
  height: 40px;
  width: auto;
  margin-left: 1rem;
  ${props => props.disabled && 'opacity: 0.5;'}
`;

const MoveRobBtnContainer = styled.div`
  position: absolute;
  right: 2rem;
`;

const MoveRobBtn = styled.button`
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.75;
  
  &:focus{
    box-shadow: none;
    outline: none;
    border: none;
  }
`;

const BtnIconStyle = {
  marginRight: '1.5rem'
};

const Rod = (
  {
    id,
    size,
    space,
    index,
    move,
    project,
    updateProject,
    setAlert,
    targetNeighbor,
    rodForMachine,
    rodForMailbox,
    possibleMailboxRod,
    mailboxPlacement,
  }) => {
  const dispatch = useDispatch();

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'rod',
    drop(item) {
      move(item.index, index, MOVE_TYPE_DROP);
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      move(dragIndex, hoverIndex, MOVE_TYPE_HOVER);

      item.index = hoverIndex
    },
  });

  const [{isDragging}, drag] = useDrag({
    item: {type: 'rod', id, index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const insertNew = (newRod) => update(project.rods, {
      $splice: [
        [index, 0, newRod],
      ],
    },
  );

  const copy = () => {
    const newProject = {...project};

    const newRod = {
      id: uuid_v4(),
      size: size,
      space: space.space || project.defaultSpace,
    };

    newProject.rods = insertNew(newRod);

    const heightAfterUpdate = calcProjectHeight(newProject);
    if (heightAfterUpdate > project.heightExpected) {
      const title = `Nie możesz skopiować kształtownika. Pozostało Ci ${project.heightExpected - project.height} mm.`;
      setAlert({title});
    } else {
      updateProject(newProject);
    }
  };

  const remove = () => {
    const newProject = {...project};

    newProject.rods = update(project.rods, {$splice: [[index, 1]]});

    updateProject(newProject);
  };

  const chooseRodForMachine = () => {
    const newRod = {
      size: size,
      space: space.space,
      id: id,
      rodForMachine: !rodForMachine,
    };

    dispatch({ type: ROD_FOR_MACHINE_CHOSEN, projectId: project.id, rod: newRod, machineRodChosen: !rodForMachine })
  };

  const chooseRodForMailbox = () => {
    const newRod = {
      size: size,
      space: space.space,
      id: id,
      rodForMailbox: !rodForMailbox,
    };

    dispatch({ type: ROD_FOR_MAILBOX_CHOSEN, projectId: project.id, rod: newRod, mailboxRodChosen: !rodForMailbox })
  };

  const shouldMachineCheckboxShow = (project.type === FENCE_TYPE_GATE_TILTING && project.gateTiltingMachine && size === 80);
  const machineCheckboxBeDisabled = (shouldMachineCheckboxShow && project.machineRodChosen && !rodForMachine);

  const mailboxPlacementChange = (e) => {
    dispatch({ type: MAILBOX_PLACEMENT_CHOSEN, projectId: project.id, mailboxPlacement: e.target.value })
  };

  const MailboxPlacementSelect = () => {
    const mailboxOptions = mailboxPlacements.map((option) => <option value={option}>{mailboxPlacementInPolish[option]}</option>);

    if (rodForMailbox && possibleMailboxRod && project.mailbox) {
      return (
        <MailboxSelectContainer className={'form-group mailboxSelectContainer'}>
          <MailboxSelect className={'form-control'} id={`mailboxPlacement__${id}`} value={mailboxPlacement}  onChange={mailboxPlacementChange}>
            {mailboxOptions}
          </MailboxSelect>
        </MailboxSelectContainer>
      )
    }
  return null;
  };

  return (
    <RodGroup ref={ref} targetNeighbor={targetNeighbor} dragging={isDragging}>
      <Container data-size={size}>
        <div>
          <Index>{index + 1}</Index>
        </div>
          <RodSize
          index={index}
          id={id}
          size={size}
          project={project}
          updateProject={updateProject}
          setAlert={setAlert}
          disabled={rodForMachine}
        />
        <BtnContainer>
          <RodBtn type={'button'} className={'btn rodActionBtn'} onClick={copy} data-sentry="duplicate-rod">
            <FontAwesomeIcon icon={faPlus} style={BtnIconStyle}/>
            Duplikuj
          </RodBtn>
          <RodBtn type={'button'} className={'btn rodActionBtn'} onClick={remove} data-sentry="remove-rod">
            <FontAwesomeIcon icon={faTrashAlt} style={BtnIconStyle}/>
            Usuń
          </RodBtn>
        </BtnContainer>
        {shouldMachineCheckboxShow &&
        (<MachineInputContainer className="form-check">
          <input
              className="form-check-input"
              type="checkbox"
              id={`machineRod__${id}`}
              onChange={chooseRodForMachine}
              checked={rodForMachine}
              disabled={machineCheckboxBeDisabled}
            />
            <MachineCheckboxLabel
              className="form-check-label"
              htmlFor={`machineRod__${project.id}`}
              disabled={machineCheckboxBeDisabled}
            >
              Wzmocnienie do automatyki
            </MachineCheckboxLabel>
          <Img src="/img/icon/machineIcon.png" alt="" disabled={machineCheckboxBeDisabled}/>
        </MachineInputContainer>)}
        {possibleMailboxRod && project.mailbox && (
          <MachineInputContainer className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`mailboxRod__${id}`}
              onChange={chooseRodForMailbox}
              checked={rodForMailbox}
            />
            <MachineCheckboxLabel
              className="form-check-label"
              htmlFor={`mailboxRod__${project.id}`}
            >
              Skrzynka Invisible
            </MachineCheckboxLabel>
          </MachineInputContainer>
        )}
        <MailboxPlacementSelect/>
        <MoveRobBtnContainer>
          <MoveRobBtn type={'button'} className={'btn rodActionBtn rodMoveBtn'}>
            Przesuń
            <FontAwesomeIcon icon={faEllipsisV} style={{marginLeft: '1rem'}}/>
          </MoveRobBtn>
        </MoveRobBtnContainer>
      </Container>
      <RodSpace
        id={id}
        space={space.space}
        min={space.min}
        disabled={space.disabled}
        project={project}
        updateProject={updateProject}
        setAlert={setAlert}
      />
    </RodGroup>
  );
};

Rod.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  space: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  move: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default Rod;
