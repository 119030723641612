import React from 'react';
import {findProjectIdInUrl} from './LoadProjectFromUrl';
import {useSelector} from 'react-redux';
import {getNumberOfGateSelfSupportingWithLabel, getNumberOfGateTiltingWithLabel, getNumberOfGatewayWithLabel, getNumberOfSpanWithLabel} from '../Header';
import {FENCE_TYPE_GATE_SELF_SUPPORTING, FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY, FENCE_TYPE_SPAN} from 'fenceTypesConstants';
import styled from 'styled-components';

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  align-items: baseline;
`;

const Title = styled.h1`
  font-weight: lighter;
`;

const Body = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const Btn = styled.button`
  border: none;
  border-radius: 22px;
  padding: 0.5rem 2rem;
  margin-right: 1rem;

  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
`;

const LoadProjectFromUrlAlert = () => {
  const numberOfProjectsPerType = useSelector(state => state.app.numberOfProjectsPerType);

  // TODO move this to state as we check it many times
  const projectId = findProjectIdInUrl();

  if (!projectId) {
    return null;
  }

  const close = () => document.querySelector('#loadProjectFromUrlModal').style.display = 'none';

  return (
    <div id={'loadProjectFromUrlModal'} className="modal" tabIndex="-1" role="dialog" style={{display: 'block'}}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <ModalHeader>
            <Title>Otwierasz zapisany projekt</Title>
          </ModalHeader>
          <Body className="modal-body">
            <p>Projekt zawiera:</p>
            <ul>
              <li>{getNumberOfSpanWithLabel(numberOfProjectsPerType[FENCE_TYPE_SPAN])}</li>
              <li>{getNumberOfGatewayWithLabel(numberOfProjectsPerType[FENCE_TYPE_GATEWAY])}</li>
              <li>{getNumberOfGateTiltingWithLabel(numberOfProjectsPerType[FENCE_TYPE_GATE_TILTING])}</li>
              <li>{getNumberOfGateSelfSupportingWithLabel(numberOfProjectsPerType[FENCE_TYPE_GATE_SELF_SUPPORTING])}</li>
            </ul>
          </Body>
          <div className="modal-footer">
            <Btn className={'btn btn-success'} onClick={close}>
              Rozumiem
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadProjectFromUrlAlert;
