import {useFormikContext} from 'formik';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN
} from 'fenceTypesConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import MenuInput from './MenuInput';
import { DISAPPEARING_TIMEOUT } from '../App';
import { useSelector } from 'react-redux';
import {Extra} from './Context';

export const MenuInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MenuInputLabel = styled.label`
  color: #c0c2c2;
  font-size: 0.8rem;
`;

export const MenuInputText = styled.span`
   position: absolute;
   color: #ffffff;
   top: 35px;
   left: 80px;
   pointer-events: none;
`;

export const MenuInputBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
`;

export const MenuInputBtn = styled.button`
  border-radius: 50%;
  padding: 0.35rem 0.70rem;
  background: #575b5d;
  color: #ffffff;
  border: none;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const MenuInputSmall = styled.small`
  color: #c0c2c2;
  font-size: 0.7rem;
`;

const getMax = (type) => {
  switch (type) {
    case 'gateway':
      return 2300;
    case 'gateTilting':
    case 'gateSelfSupporting':
      return 2300;
    default:
      return 2100;
  }
}

const MenuHeight = ({ menu, updateProject }) => {
  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));
  const formik = useFormikContext();
  const index = formik.values.projects.findIndex(p => p.id === project.id);
  const [info, setInfo] = useState(null);
  const [tmpVal, setTmpVal] = useState(project.heightExpected || '');
  const [timer, setTimer] = useState(null);

  useEffect(() => setTmpVal(project.heightExpected), [project.heightExpected]);

  const getLabel = () => {
    if (project.type === FENCE_TYPE_SPAN) {
      return 'Wysokość przęsła';
    } else if (project.type === FENCE_TYPE_GATEWAY) {
      return 'Wysokość furtki';
    } else if (project.type === FENCE_TYPE_GATE_TILTING) {
      return 'Wysokość bramy uchylnej';
    } else if (project.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
      return 'Wysokość bramy samonośnej';
    } else {
      return 'Wysokość';
    }
  };

  const change = (newVal, clickThroughButton) => {
    const min = menu.height.min;
    const max = getMax(project.type);
    clearTimeout(timer);

    setInfo(null);

    if (!project) {
      setInfo('Wybierz rodzaj projektu.');

      return;
    }

    newVal = parseInt(newVal) || '';
    setTmpVal(newVal);

    let err;
    if (typeof newVal === 'number') {
      if (newVal < min) {
        err = `Min. wysokość to ${min} mm.`;
      } else if (newVal > max) {
        err = `Maks. wysokość to ${max} mm.`;
      }
    }

    const setValues = () => {
      if (err) {
        setInfo(err);
      } else {
        formik.setFieldValue(`projects[${index}]heightExpected`, newVal);
        updateProject({ ...project, heightExpected: newVal });
        setInfo('Zapisano.');
        setTimeout(() => setInfo(null), DISAPPEARING_TIMEOUT);
      }
    };

    if (clickThroughButton) {
      setValues();
    } else {
      setTimer(setTimeout(() => {
        setValues();
      }, 700));
    }
  };

  return (
    <MenuInputsContainer className="form-group">
      <MenuInputLabel htmlFor="height">{getLabel()}</MenuInputLabel>
      <InputContainer>
        <MenuInputText>mm</MenuInputText>
        <MenuInput
          id="height"
          step={10}
          change={(e) => change(e.target.value, false)}
          value={tmpVal || ''}
          sentry="height"
        />
        <MenuInputBtnContainer>
          <MenuInputBtn style={{ marginRight: '0.5rem' }} onClick={() => change(parseInt(project.heightExpected + 10), true)}>
            <FontAwesomeIcon icon={faPlus} style={{ color: '#ffffff' }}/>
          </MenuInputBtn>
          <MenuInputBtn onClick={() => change(parseInt(project.heightExpected - 10), true)}>
            <FontAwesomeIcon icon={faMinus} style={{ color: '#ffffff' }}/>
          </MenuInputBtn>
        </MenuInputBtnContainer>
      </InputContainer>
      <Extra><upper>*</upper> Producent dopuszcza tolerancję dla wysokości w zakresie 10 mm.</Extra>
      <MenuInputSmall className="form-text">{info}</MenuInputSmall>
    </MenuInputsContainer>
  );
};

MenuHeight.propTypes = {
  project: PropTypes.object,
  menu: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
};

export default MenuHeight;
