import {connect} from 'react-redux';
import Alert from '../components/Alert';
import {setAlert} from '../actions';

const mapStateToProps = (state) => ({
  alert: state.app.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (alert) => dispatch(setAlert(alert)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);
