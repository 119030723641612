import {connect} from 'react-redux';
import Menu from '../../components/Menu/Menu';
import {setProjectVisualisation, updateProject} from '../../actions';

const mapStateToProps = (state) => ({
  menu: state.app.menu,
  projects: state.app.projects,
  currentProjectId: state.app.currentProjectId,
  canStartDesigning: state.app.canStartDesigning,
  projectVisualisation: state.app.projectVisualisation,
});

const mapDispatchToProps = (dispatch) => ({
  updateProject: (project) => dispatch(updateProject(project)),
  setProjectVisualisation: (projectVisualisation) => dispatch(setProjectVisualisation(projectVisualisation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
