import React from 'react';
import PropTypes from 'prop-types';
import './ProjectBoardInfo.css';
import styled from 'styled-components';
import { FENCE_TYPE_GATE_TILTING, FENCE_TYPE_SPAN } from 'fenceTypesConstants';
import { useDispatch } from 'react-redux';
import { MACHINE_MODAL_IS_SHOWING } from '../actions';

const ShowModalBtn = styled.button`
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  background-color: #f2f2f2;
  &:hover, :active, :focus {
    border: 1px solid #bfbfbf;
  }
`;

const ProjectBoardInfo = ({ project }) => {
  const dispatch = useDispatch();

  const showMsg = project && project.rods.length;

  const spanException = showMsg && project.type === FENCE_TYPE_SPAN && project.width > 3000 ? (
    <p>Z powodu przęsła szerszego niż 3000 mm, musi być ono uzupełnione poziomą belką która stabilizuje
      konstrukcje.<br/><b>Nie ma ona wpływu na kształt</b>, ponieważ <b>widoczna jest tylko od wewnątrz posesji</b>.
    </p>) : null;

  const showMachineModal = () => {
    dispatch({ type: MACHINE_MODAL_IS_SHOWING, machineModalIsShowing: true })
  };

  const gateTiltingMachineInfo = showMsg && project.type === FENCE_TYPE_GATE_TILTING ? (
    <>
    <p>W przypadku bram uchylnych wskaż profil do automatyki bramowej.
      Więcej informacji na temat automatyki bramowej znajdziesz również w katalogu technicznym Konsport w rozdziale <b>DIVERSO</b> oraz <b>DIVERSO STANDARD</b> w sekcji
      {" "}
      <b style={{color: '#ff0000'}}>Dodatkowy poziom</b>.
    </p>
      <ShowModalBtn onClick={showMachineModal}>
        Ponownie wyświetl informacje na temat automatyki bramowej
      </ShowModalBtn>
    </>) : null;

  if (!spanException && !gateTiltingMachineInfo) {
    return null;
  }

  return (
    <section id={'projectBoardInfo'}>
      {gateTiltingMachineInfo}
      {spanException}
    </section>
  );
};

ProjectBoardInfo.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectBoardInfo;
