import React, {useState} from 'react';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';
import {SET_PROJECT_VISUALISATION} from '../../actions';
import styled from 'styled-components';
import { MAX_SCALE } from '../ProjectVisualisation/ProjectVisualisation';

export const PROJECT_VISUALISATION_GENERATE_ONE = 'one';
export const PROJECT_VISUALISATION_GENERATE_ALL = 'all';

const Button = styled.button`
  background: ${props => props.showOptions ? '#2d3234' : '#575b5d'};
  color: #eceded;
  border-radius: 40px;
  padding: 0.5rem 2.5rem;
  border: ${props => props.showOptions ? '1px solid #eceded' : '1px solid #575b5d'};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline: none;
  box-shadow: none !important;
  & > svg {
      color: #eceded;
      margin-right: 1rem;
   }
  
  &:hover {
    background: #eceded;
    color: #575b5d;
    & > svg {
      color: #575b5d;
   }
  }
`;

const List = styled.ul`
  list-style-type: none;
  position: absolute;
  margin-top: -8.75rem;
  z-index: 999;
  background: #2d3234;
  padding-right: 20%;
`;

const OptionBtn = styled.button`
  background: #575b5d;
  color: #eceded;
  border-radius: 40px;
  padding: 0.5rem 2.5rem;
  margin-top: 0.25rem;
  margin-left: -2.5em;
  width: 322px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  & > svg {
    margin-right: 1rem;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  &:hover {
    background: #eceded;
    color: #575b5d;
  }
`;

const VisualisationBtn = () => {
  const [showOptions, setShowOptions] = useState(false);

  const dispatch = useDispatch();

  const projectVisualisation = useSelector(state => state.app.projectVisualisation);

  const generateClick = (option) => {
    const newProjectVisualisation = {
      ...projectVisualisation,
      option,
      scale: MAX_SCALE,
      show: true,
    };

    dispatch({type: SET_PROJECT_VISUALISATION, projectVisualisation: newProjectVisualisation});
  };

  const showOptionsClick = () => {
    setShowOptions(!showOptions);

    const tenSeconds = 10000;
    setTimeout(() => {
      setShowOptions(false);
    }, tenSeconds);
  };

  const icon = showOptions ? <FontAwesomeIcon icon={faTimes}/> : <FontAwesomeIcon icon={faSearch}/>;

  return (
    <div>
      <Button
        className="btn"
        type="button"
        data-sentry="generate-visualisation"
        onClick={showOptionsClick}
        showOptions={showOptions === true}
      >
        {icon}
        <span>{showOptions ? 'Anuluj' : 'Generuj podgląd'}</span>
      </Button>
      {showOptions &&
      <List>
        <li style={{marginBottom: '0.25rem'}}>
          <OptionBtn
            className={'btn'}
            onClick={() => generateClick(PROJECT_VISUALISATION_GENERATE_ONE)}
            type={'button'}
          >
            <FontAwesomeIcon icon={faSearch}/>
            <span>Obecny projekt</span>
          </OptionBtn>
          <OptionBtn
            className={'btn'}
            type={'button'}
            onClick={() => generateClick(PROJECT_VISUALISATION_GENERATE_ALL)}
          >
            <FontAwesomeIcon icon={faSearch}/>
            <span>Wszystkie</span>
          </OptionBtn>
        </li>
      </List>
      }
    </div>
  );
};

export default VisualisationBtn;
