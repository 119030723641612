export const FirstCSpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const FirstCGateway = {
  additionalElementTop: {
    space: 17,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    }
  ],
};

export const FirstCGateTilting = {
  additionalElementTop: {
    space: 17,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    }
  ],
};

export const FirstCGateSelfSupporting = {
  additionalElementTop: {
    space: 14,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 14,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 14,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 14,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    },
    {
      size: 80,
      space: 15,
    },
    {
      size: 40,
      space: 15,
    },
    {
      size: 20,
      space: 15,
    }
  ],
};
