import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MAILBOX_CHECKED } from '../../actions';
import { CheckboxLabel, CheckboxInputContainer } from './GateTiltingMachine';

const Mailbox = ({ project }) => {
  const dispatch = useDispatch();

  const mailbox = useSelector (state => state.app.projects.find(p => p.id === project.id)?.mailbox) || false;

  const changeMailbox = () => {
    dispatch({
      type: MAILBOX_CHECKED,
      projectId: project.id,
      mailbox: !mailbox,
    });
  };

  return (
    <div className="form-check">
      <CheckboxInputContainer>
        <input
          className="form-check-input"
          type="checkbox"
          id={`mailbox__${project.id}`}
          data-sentry="include-invisible-mailbox"
          onChange={changeMailbox}
          checked={mailbox}
        />
        <CheckboxLabel className="form-check-label" htmlFor={`mailbox__${project.id}`}>
          Uwzględnij w projekcie skrzynkę Invisible.
        </CheckboxLabel>
      </CheckboxInputContainer>
    </div>
  );
};

export default Mailbox;
