import React from 'react';
import styled from 'styled-components';
import { STEP_ONE_PROJECT_SELECT, STEP_THREE_ROD_COLLECTION, STEP_TWO_PROJECT_WIDTH } from '../../ProjectConstants';

const Container = styled.div`
  position: absolute;
  border-radius: 22px;
  width: 280px;
  background: #0064c7;
  color: #ffffff;
  padding: 0.75rem 1rem;
  box-shadow: 2px 2px 5px 1px #000000;
  z-index: 999;
  ${props => props.bottomBarTooltip ? 'bottom 90px; left: 250px' : 'top: 30px; left: 350px'};
`;

const Title = styled.span`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Step = styled.span`
  opacity: 0.75;
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

const Tooltip = ({situation}) => {

  const title = () => {
    if (situation === STEP_ONE_PROJECT_SELECT) {
      return `Rodzaj ogrodzenia`;
    } else if (situation === STEP_TWO_PROJECT_WIDTH) {
      return `Wymiary`;
    } else if (situation === STEP_THREE_ROD_COLLECTION) {
      return `Własny wzór`;
    } else {
      return `Pamiętaj`;
    }
  };

  const information = () => {
    if (situation === STEP_ONE_PROJECT_SELECT) {
      return `Wybierz 1 z 4 elementów ogrodzenia, od którego chcesz rozpocząć projektowanie.`;
    } else if (situation === STEP_TWO_PROJECT_WIDTH) {
      return `Wybierz wysokość, szerokość oraz domyślny odstęp pomiędzy kształtownikami.`;
    } else if (situation === STEP_THREE_ROD_COLLECTION) {
      return `Stwórz swój idealny wzór dodając do projektu wybrane profile.`;
    } else {
      return null;
    }
  };

  const step = () => {
    if (situation === STEP_ONE_PROJECT_SELECT) {
      return `Krok 1 z 3`;
    } else if (situation === STEP_TWO_PROJECT_WIDTH) {
      return `Krok 2 z 3`;
    } else if (situation === STEP_THREE_ROD_COLLECTION) {
      return `Krok 3 z 3`;
    } else {
      return null;
    }
  };

  return (
    <Container bottomBarTooltip={situation === STEP_THREE_ROD_COLLECTION}>
      <Title>{title()}<br/></Title>
      <span>{information()}<br/></span>
      <Step>{step()}</Step>
    </Container>
  )
};

export default Tooltip;
