import React from 'react';
import PropTypes from 'prop-types';
import ProjectVisualisationBody from './ProjectVisualisationBody';
import './ProjectVisualisationGateway.css';

const ProjectVisualisationGateway = ({project, projectVisualisation, containerStyle}) => {
  return (
    <div style={containerStyle} className={'projectVisualisation projectVisualisationGateway'} data-scale={projectVisualisation.scale}>
      <div className={'projectVisualisationGateway__spanLeftBottom'}></div>
      <div className={'projectVisualisationGateway__spanLeft'}></div>
      <div className={'projectVisualisationGateway__spanLeftTop'}></div>
      <div className={'projectVisualisationBody projectVisualisationBody__gatewayTop'}></div>
      <ProjectVisualisationBody project={project} projectVisualisation={projectVisualisation}/>
      <div className={'projectVisualisationBody projectVisualisationBody__gatewayBottom'}></div>
      <div className={'projectVisualisationGateway__spanRightBottom'}></div>
      <div className={'projectVisualisationGateway__spanRight'}></div>
      <div className={'projectVisualisationGateway__spanRightTop'}></div>
    </div>
  );
};

ProjectVisualisationGateway.propTypes = {
  project: PropTypes.object.isRequired,
  projectVisualisation: PropTypes.object.isRequired,
  containerStyle: PropTypes.object.isRequired,
};

export default ProjectVisualisationGateway;
