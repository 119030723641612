import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_PDF_SHOW_WIDTH} from '../../../actions';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const Label = styled.label`
  font-size: 1.25rem;
`;

const WidthP = styled.p`
  font-size: 1rem;
  text-align: left;
`;

const WidthOption = () => {
  const showWidth = useSelector(state => state.app.pdf.showWidth);

  const dispatch = useDispatch();

  const change = () => dispatch({type: SET_PDF_SHOW_WIDTH, showWidth: !showWidth});

  const divStyle = {
    cursor: 'pointer',
    marginBottom: '2rem',
    padding: '1rem 2.5rem',
  };

  const pStyle = {
    margin: 0,
  };

  return (
    <div className="form-check" style={divStyle}>
      <InputContainer>
      <input
        className="form-check-input"
        type="checkbox"
        id="pdfShowWidth"
        onChange={change}
        checked={!showWidth}
      />
      <Label className="form-check-label" htmlFor="pdfShowWidth">Ukryj szerokość w PDF</Label>
      </InputContainer>
      <WidthP style={pStyle} onClick={change}>
        Jeżeli zamawiasz kilka elementów o tej samej wysokości, ale o różnych szerokościach,<br/>
        będziesz mógł użyć jednego projektu dla Twojego całego zamówienia.
      </WidthP>
    </div>
  );
};

export default WidthOption;
