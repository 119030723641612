import React from 'react';
import Rods from './Rod/Rods';
import PropTypes from 'prop-types';
import AdditionalElementTop from './Rod/AdditionalElementTop';
import AdditionalElementBottom from './Rod/AdditionalElementBottom';
import styled from 'styled-components';

const Container = styled.section`
  margin-left: 450px;
  padding: 1rem 0 4rem 2rem;
  width: 70%;
  margin-bottom: 5rem;
  margin-right: 2rem;
`;

const ProjectBoard = ({project, updateProject, setAlert}) => {
  if (!project || !project.rods.length) {
    return null;
  }

  return (
    <Container id={'projectBoard'}>
      <div>
        <AdditionalElementTop
          project={project}
          updateProject={updateProject}
          setAlert={setAlert}
        />
        <Rods updateProject={updateProject} setAlert={setAlert}/>
        <AdditionalElementBottom project={project}/>
      </div>
    </Container>
  );
};

ProjectBoard.propTypes = {
  project: PropTypes.object,
  updateProject: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default ProjectBoard;
