import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN
} from 'fenceTypesConstants';
import ProjectVisualisationSpan from './ProjectVisualisationSpan';
import ProjectVisualisationGateway from './ProjectVisualisationGateway';
import ProjectVisualisationGateTilting from './ProjectVisualisationGateTilting';
import ProjectVisualisationGateSelfSupporting from './ProjectVisualisationGateSelfSupporting';
import domtoimage from 'dom-to-image';
import { PROJECT_VISUALISATION_GENERATE_ALL, PROJECT_VISUALISATION_GENERATE_ONE } from '../Menu/VisualisationBtn';
import './ProjectVisualisation.css';
import styled from 'styled-components';

const Container = styled.div`
  overflow: hidden;
  display: block;
`;

const Modal = styled.div`
  max-width: calc(100vw - 35%) !important;
`;

const ModalHeader = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 1rem 10rem;
`;

const Title = styled.h1`
  font-weight: lighter;
`;

const ModalInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 11rem;
`;

const ProjectParameters = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScaleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ScaleSpan = styled.span`
  margin-right: 1rem;
`;

const ZoomBtn = styled.button`
  border-radius: 50px;
  background: #575b5d;
  color: #d5d6d6;
  
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const ImageBtn = styled.button`
  border-radius: 50px;
  padding: 0.5rem 1.5rem;
`;

const ModalBody = styled.div`
  overflow: auto;
  padding: 2rem;
  max-height: 65vh;
`;

export const MIN_SCALE = 2;
export const MAX_SCALE = 4;

export const Visualisation = ({ p, projectVisualisation }) => {
  const floatSide = projectVisualisation.allProjects ? 'right' : 'left';

  const containerStyle = {
    width: Math.round(p.width / projectVisualisation.scale),
    height: Math.round(p.heightExpected / projectVisualisation.scale),
    float: floatSide,
  };

  const props = {
    project: p,
    projectVisualisation,
    containerStyle,
  };

  switch (p.type) {
    case FENCE_TYPE_GATE_SELF_SUPPORTING:
      return <ProjectVisualisationGateSelfSupporting {...props} />;
    case FENCE_TYPE_GATE_TILTING:
      return <ProjectVisualisationGateTilting {...props} />;
    case FENCE_TYPE_GATEWAY:
      return <ProjectVisualisationGateway {...props} />;
    case FENCE_TYPE_SPAN:
      return <ProjectVisualisationSpan {...props} />;
    default:
      throw new Error(`Unknown project type "${p.type}".`);
  }
};

const ProjectVisualisation = ({ projects, projectVisualisation, project = null, setProjectVisualisation }) => {

  if (!projectVisualisation.show) {
    return null;
  }

  const zoomOut = (e) => {
    e.preventDefault();

    const newScale = projectVisualisation.scale + 1;
    if (newScale > MAX_SCALE) {
      return false;
    }

    setProjectVisualisation({ ...projectVisualisation, scale: newScale })
  };

  const zoomIn = (e) => {
    e.preventDefault();

    const newScale = projectVisualisation.scale - 1;
    if (newScale < MIN_SCALE) {
      return false;
    }

    setProjectVisualisation({ ...projectVisualisation, scale: newScale })
  };

  const close = (e) => {
    e.preventDefault();

    setProjectVisualisation({ ...projectVisualisation, show: false });
  };

  const Visualisations = () => {
    switch (projectVisualisation.option) {
      case PROJECT_VISUALISATION_GENERATE_ONE:
        return <Visualisation p={project} projectVisualisation={projectVisualisation}/>;
      case PROJECT_VISUALISATION_GENERATE_ALL:
        return projects.map((p, index) => <Visualisation key={index} p={p}
                                                         projectVisualisation={projectVisualisation}/>);
      default:
        throw new Error();
    }
  };

  const camera = (e) => {
    e.preventDefault();

    const prevScale = projectVisualisation.scale;
    if (prevScale !== MAX_SCALE) {
      setProjectVisualisation({ ...projectVisualisation, scale: MAX_SCALE });
    }

    domtoimage.toPng(document.getElementById('projectVisualisations'), { quality: 0.8 })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'diverso-wizualizacja.png';
        link.href = dataUrl;
        link.click();

        setProjectVisualisation({ ...projectVisualisation, scale: prevScale });
      });
  };

  const modalInnerMargin = projects.length * 64;
  const modalInnerWidth = projects.reduce((accumulator, b) => accumulator + Math.round(b.width / projectVisualisation.scale), 0) + modalInnerMargin;

  const title = projectVisualisation.allProjects ? 'PODGLĄD PROJEKTÓW' : `PODGLĄD PROJEKTU`;

  const Parameters = () => {
    if (projectVisualisation.allProjects) {
      return null;
    }
    return (
      <ProjectParameters id={'projectVisualisationModalProjectInfo'}>
              <span>
                Wysokość: <b>{project.height} mm</b>
                <br/>
                Szerokość: <b>{project.width} mm</b>
              </span>
      </ProjectParameters>
    )
  };

  return (
    <Container id={'projectVisualisationModal'} className="modal" tabIndex="-1" role="dialog">
      <Modal className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div>
            <ModalHeader id={'projectVisualisationModalActions'}>
              <div id="projectVisualisationModalActionHeader">
                <Title>{title}</Title>
              </div>
              <div id="projectVisualisationModalActionCloseContainer">
                <button className={'btn'} type={'button'} onClick={close}>
                  <FontAwesomeIcon icon={faTimes} size={'2x'}/>
                </button>
              </div>
            </ModalHeader>
          </div>
          <ModalInfo>
            <Parameters/>
            <ScaleContainer id="projectVisualisationModalActionScaleContainer">
              <div id="projectVisualisationModalActionScaleInfo">
                <ScaleSpan>Rozmiar<br/>poglądu</ScaleSpan>
              </div>
              <div id="projectVisualisationModalActionScaleBtnsContainer">
                <ZoomBtn
                  className="btn btn-secondary"
                  type="button"
                  onClick={zoomIn}
                  disabled={projectVisualisation.scale <= MIN_SCALE}
                  style={{ marginRight: "0.5rem" }}
                >
                  <FontAwesomeIcon icon={faPlus}/>
                </ZoomBtn>
                <ZoomBtn
                  className="btn btn-secondary"
                  type="button"
                  onClick={zoomOut}
                  disabled={projectVisualisation.scale > 3}
                >
                  <FontAwesomeIcon icon={faMinus}/>
                </ZoomBtn>
              </div>
            </ScaleContainer>
            <div id={'projectVisualisationModalActionContainer'}>
              <ImageBtn className={'btn btn-danger btn-icon-left'} type={'button'} onClick={camera}>
                <FontAwesomeIcon icon={faCamera}/>
                <span>Zapisz jako obraz</span>
              </ImageBtn>
            </div>
          </ModalInfo>
          <ModalBody
            className="modal-body"
          >
            <div id={'projectVisualisations'} style={{ width: modalInnerWidth }}>
              <Visualisations/>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </Container>
  );
};

ProjectVisualisation.propTypes = {
  project: PropTypes.object,
  projects: PropTypes.array.isRequired,
  projectVisualisation: PropTypes.object.isRequired,
  setProjectVisualisation: PropTypes.func.isRequired,
};

export default ProjectVisualisation;
