import React from 'react';
import styled from 'styled-components';

export const CheckboxInputContainer = styled.div`
  display: flex;
  align-items: baseline;
  ${props => props.disabled && 'cursor: not-allowed;'}
`;

export const CheckboxLabel = styled.label`
  font-size: 1rem;
  color: #c0c2c2;
  
  &:disabled {
    color: #c0c2c2 !important;
    pointer-events: none;
  }
`;

const GateTiltingMachine = ({ project }) => {
  // const dispatch = useDispatch();

  // const projects = useSelector(state => state.app.projects);
  // const index = projects.findIndex((p) => p.id === project.id);
  // const gateTiltingMachine = useSelector(state => state.app.projects[index].gateTiltingMachine) || false;

  // const changeMachine = () => {
  //   dispatch({
  //     type: GATE_TILTING_MACHINE_CHECKED,
  //     projectId: project.id,
  //     gateTiltingMachine: !gateTiltingMachine,
  //   });
  // };

  return (
    <div className="form-check">
      <CheckboxInputContainer>
        <input
          className="form-check-input"
          type="checkbox"
          id={`gateTiltingMachine__${project.id}`}
          // onChange={changeMachine}
          checked={true}
        />
        <CheckboxLabel className="form-check-label" htmlFor={`gateTiltingMachine__${project.id}`}>Uwzględnij przygotowanie pod automatykę w
          projekcie.</CheckboxLabel>
      </CheckboxInputContainer>
    </div>
  );
};

export default GateTiltingMachine;
