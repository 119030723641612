import React, {useState} from 'react';
import ProjectOption from './ProjectOption';
import {useDispatch, useSelector} from 'react-redux';
import WidthOption from './WidthOption';
import {generateHtml, generatePdf, PDF_GENERATE_OPTION_ALL, PDF_GENERATE_OPTION_ONE} from '../PdfBtn';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {SET_PDF_SHOW_MODAL} from '../../../actions';
import {DISAPPEARING_TIMEOUT} from '../../App';
import styled from 'styled-components';

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  align-items: center;
`;

const Title = styled.h1`
  font-weight: lighter;
  margin: 2rem 3rem 0 1rem;
`;

const ModalBody = styled.div`
  padding: 1rem 3rem;
`;

const ChosenOptions = styled.div`
  padding: 0 2rem;
`;

const PdfBtn = styled.button`
  border-radius: 30px;
  padding: 0.5rem 3rem;
`;

const Modal = () => {
  const projects = useSelector(state => state.app.projects);
  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));
  const showWidth = useSelector(state => state.app.pdf.showWidth);
  const pdfOptions = useSelector(state => state.app.pdf);

  const [option, setOption] = useState(PDF_GENERATE_OPTION_ALL);
  const [processing, setProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState(null);

  const dispatch = useDispatch();

  if (!pdfOptions.showModal) {
    return null;
  }

  const close = () => dispatch({type: SET_PDF_SHOW_MODAL, showModal: false});

  const generate = (option) => {
    setProcessing(true);

    let html;

    if (option === PDF_GENERATE_OPTION_ONE) {
      html = generateHtml([project], pdfOptions);
    } else if (option === PDF_GENERATE_OPTION_ALL) {
      html = generateHtml(projects, pdfOptions);
    } else {
      throw new Error();
    }

    const errorMessage = (<span>Wystąpił błąd.<br/>Skontaktuj się z Konsport.</span>);

    generatePdf(html).then((response) => response.json()).then((response) => {
      if (response.url) {
        window.open(response.url);

        // keep for debugging
        // window.open('about:blank', '_blank').document.write(html);

        setResponse('PDF wygenerowany poprawnie.');
        setTimeout(() => dispatch({type: SET_PDF_SHOW_MODAL, showModal: false}), DISAPPEARING_TIMEOUT);
      } else {
        setIsError(true);
        setResponse(errorMessage);
        setProcessing(false);
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsError(true);
      setResponse(errorMessage);
    }).finally(() => {
      setProcessing(false);
    });
  };

  return (
    <div
      id="generatePdfModal"
      className="modal"
      tabIndex="-1"
      role="dialog"
      style={{display: 'block'}}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <ModalHeader>
            <Title className="modal-title">GENERUJ PDF</Title>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ProjectOption option={option} setOption={setOption}/>
            <WidthOption/>
            <ChosenOptions>
              <p>Wybrałeś opcje:</p>
              <ul>
                <li>{option === PDF_GENERATE_OPTION_ALL ? 'Generuj wszystkie projekty' : 'Generuj jeden (obecny) projekt'}</li>
                <li>{showWidth ? 'Pokaż szerokość' : 'Ukryj szerokość'}</li>
              </ul>
            </ChosenOptions>
          </ModalBody>
          <div className={'modal-footer'} style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <p className={isError ? 'error' : ''}>{response}</p>
            </div>
            <div>
              <PdfBtn
                type="button"
                className={'btn btn-danger btn-icon-left'}
                onClick={() => generate(option)}
              >
                {processing && <FontAwesomeIcon className="spinner" icon={faSpinner}/>}
                {!processing && <FontAwesomeIcon icon={faFilePdf}/>}
                <span>Generuj PDF</span>
              </PdfBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
