import React, {useState} from 'react';
import {useFormikContext} from 'formik';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import fencesTypes, {FENCE_TYPE_SPAN} from 'fenceTypesConstants';

import {areAllProjectFenceTypesExists, projectBase} from './ProjectType';
import {ADD_PROJECT, SET_ALERT, SET_EXAMPLE_MODEL} from '../../actions';
import {createAlertInCaseOfErrorsBeforeChangeProjectType} from './ProjectSelect';

const DesignNextFence = () => {
  const dispatch = useDispatch();
  const formik = useFormikContext();
  const exampleModel = useSelector(state => state.app.exampleModel);
  const numberOfProjectsPerType = useSelector(state => state.app.numberOfProjectsPerType);
  const project = useSelector((state) => state.app.projects.find((project) => project.id === state.app.currentProjectId));
  const [showOptions, setShowOptions] = useState(false);
  const display = showOptions ? 'flex' : 'none';
  const designNextFence = (fenceType, project) => {
    const alert = createAlertInCaseOfErrorsBeforeChangeProjectType(project);
    if (alert) {
      dispatch({type: SET_ALERT, alert});

      return;
    }

    const allProjectFenceTypesExists = areAllProjectFenceTypesExists(numberOfProjectsPerType);
    if (exampleModel.used && fenceType !== FENCE_TYPE_SPAN && !allProjectFenceTypesExists) {
      const newExampleModel = {
        ...exampleModel,
        showAlert: true,
        nextFenceType: fenceType
      };
      dispatch({type: SET_EXAMPLE_MODEL, exampleModel: newExampleModel});

      return;
    }

    const newProject = projectBase(fenceType, numberOfProjectsPerType);
    dispatch({type: ADD_PROJECT, project: newProject});
    formik.setFieldValue('projects', [
      ...formik.values.projects,
      newProject
    ]);

    setShowOptions(false);
  };

  const li = fencesTypes.map((fenceType, index) => (
    <li key={index}>
      <TypeBtn
        className="btn"
        onClick={() => designNextFence(fenceType.name, project)}
        type="button"
      >
        <FontAwesomeIcon icon={faPlus}/>
        {fenceType.label}
      </TypeBtn>
    </li>
  ));

  const icon = showOptions ? <FontAwesomeIcon icon={faTimes}/> : <FontAwesomeIcon icon={faPlus}/>;

  const toggle = () => {
    setShowOptions(!showOptions);

    const tenSeconds = 10000;
    setTimeout(() => {
      setShowOptions(false);
    }, tenSeconds);
  };

  return (
    <div>
      <div>
        <Button
          className="btn"
          onClick={toggle}
          type="button"
          data-sentry="design-next-project"
          showOptions={showOptions === true}
        >
          {icon}
          <span>{showOptions ? 'Anuluj' : 'Projektuj kolejny element'}</span>
        </Button>
      </div>
      <div style={{display}}>
        <List>
          {li}
        </List>
      </div>
    </div>
  );
};

const Button = styled.button`
  background: ${props => props.showOptions ? '#2d3234' : '#575b5d'};
  color: #eceded;
  border-radius: 40px;
  border: ${props => props.showOptions ? '1px solid #eceded' : '1px solid #575b5d'};
  padding: 0.5rem 2.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline: none;
  box-shadow: none !important;
  & > svg {
      color: #eceded;
      margin-right: 1rem;
  }
  
  &:hover {
    background: #eceded;
    color: #575b5d;
    & > svg {
      color: #575b5d;
   }
  }
`;

const List = styled.ul`
  list-style-type: none;
  position: absolute;
  margin-top: -14.4rem;
  z-index: 999;
  background: #2d3234;
  padding-right: 20%;
`;

const TypeBtn = styled.button`
  background: #575b5d;
  color: #eceded;
  border-radius: 40px;
  padding: 0.5rem 2.5rem;
  margin-top: 0.25rem;
  margin-left: -2.5em;
  width: 322px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  & > svg {
    margin-right: 1rem;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  &:hover {
    background: #eceded;
    color: #575b5d;
  }
`;

export default DesignNextFence;
