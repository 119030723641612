import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDF_GENERATE_OPTION_ALL, PDF_GENERATE_OPTION_ONE } from '../PdfBtn';
import styled from 'styled-components';
import { faCopy, faFile } from '@fortawesome/free-regular-svg-icons';

const OptionsP = styled.p`
  font-size: 1.75rem;
  font-weight: lighter;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
`;

const Btn = styled.button`
  padding: 1.5rem 6rem 1.5rem 5rem;
  background: ${props => props.active ? '#ff0000' : '#f2f2f2'};
  border-radius: 20px;
  border: ${props => props.active ? '1px solid #ff0000' : '1px solid #f2f2f2'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  color: ${props => props.active ? '#ffffff' : '#000000'};
  ${props => props.active ? '&>svg { color: #ffffff }' : '&>svg { color: #ff0000 }'};
  
  & > svg {
    margin-right: 0.75rem;
  }
  
  &:hover {
    background: #ff0000;
    border: 1px solid #ff0000;
    color: #ffffff;
     & > svg {
    color: #ffffff;
  }
  }
  
   &:focus {
    background: #ff0000;
    border: 1px solid #ff0000;
    color: #ffffff;
    outline: none;
    box-shadow: none;
     & > svg {
    color: #ffffff;
  }
  }
 
  &:active {
    border: 1px solid #ff0000;
    outline: none;
    box-shadow: none;
  }
`;

const Option = ({ option, optionToGenerate, setOption }) => {
  const span = optionToGenerate === PDF_GENERATE_OPTION_ONE ? <span>Obecny<br/>projekt</span> : <span>Wszystkie<br/>projekty</span>;
  const icon = optionToGenerate === PDF_GENERATE_OPTION_ONE ? faFile : faCopy;

  const change = () => setOption(optionToGenerate);

  return (
    <Btn
      className="btn"
      type={"button"}
      id={`pdfModalOption--${optionToGenerate}`}
      onClick={change}
      active={option === optionToGenerate}
    >
      <FontAwesomeIcon icon={icon} size={"2x"}/>
      {span}
    </Btn>
  );
};

const ProjectOption = (props) => {

  return (
    <div style={{ padding: '1rem' }}>
      <div>
        <OptionsP>Wybierz jedną z opcji:</OptionsP>
      </div>
      <OptionsContainer>
        <Option optionToGenerate={PDF_GENERATE_OPTION_ALL} {...props}/>
        <Option optionToGenerate={PDF_GENERATE_OPTION_ONE} {...props}/>
      </OptionsContainer>
    </div>
  );
};

export default ProjectOption;
