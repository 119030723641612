import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    background: #000000;
    color: #ffffff;
    border-radius: 33px;
    border: none;
    padding: 0.25rem 2rem;
    font-weight: bold;
  
  &:focus {
    background: #000000;
    color: #ffffff;
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  &:active {
    background: #000000;
    color: #ffffff;
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

const MenuInput = (
  {
    id,
    min,
    max,
    step,
    change,
    value,
    sentry
  }
) => {

  return (
  <Input
    type="number"
    className="form-control"
    id={id}
    min={min}
    max={max}
    step={step}
    onChange={change}
    value={value}
    data-sentry={sentry}
  />
  )
};

export default MenuInput;
