import PropTypes from 'prop-types';
import {PDF_CSS_URL} from '../App';

const wrapWithinHtml = (body) => `
  <html lang="pl">
    <head>
      <meta charset="utf-8">
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous" />
      <link rel="stylesheet" href="${PDF_CSS_URL}" />
    </head>
    <body>
      ${body}
    </body>
  </html>
`;

wrapWithinHtml.propTypes = {
  body: PropTypes.string.isRequired,
};

export default wrapWithinHtml;
