import React from 'react';
import {useFormikContext} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN, fencesTypesInPolish,
  fencesTypesOnlyKeys
} from 'fenceTypesConstants';
import { areAllProjectFenceTypesExists, projectBase } from './ProjectType';
import { findProjectsInType } from '../FillParametersHelper/CopyButton';
import { SET_ALERT, SET_CURRENT_PROJECT_ID } from '../../actions';

export const createAlertInCaseOfErrorsBeforeChangeProjectType = (project) => {

  if (project && !project.heightExpected) {
    return {
      title: `Nie możesz przejść dalej ponieważ nie dokończyłeś projektu.`,
      message: <p>Wprowadź wymagane parametry w menu po lewej oraz dokończ projekt.</p>,
    };
  }

  if (project && !project.heightExpected) {
    return {
      title: `Nie możesz przejść dalej ponieważ nie dokończyłeś projektu.`,
      message: <p>Wprowadź wymagane parametry w menu po lewej oraz dokończ projekt.</p>,
    };
  }

  if (project && project.type === FENCE_TYPE_GATE_TILTING && !project.rods.some((rod) => rod.rodForMachine === true)) {
    return {
      title: `Nie możesz przejść dalej ponieważ nie dokończyłeś projektu.`,
      message: <p>Wybierz profil pod wzmocnienie do automatyki.</p>,
    };
  }

  const diff = project ? project?.heightExpected - project?.height : null;

  if (project && diff && diff > 0) {
    return {
      title: <span>Nie możesz przejść dalej ponieważ w projekcie zostało Ci <b>{diff} mm</b> do rozplanowania.</span>,
      message: (
        <React.Fragment>
          <ListSpan>Spróbuj:</ListSpan>
          <List>
            <ListItem>
              <ListItemP>
                Dodać kolejne kształtowniki lub wymień dodane na szersze aby zapełnić pozostałe milimetry,
              </ListItemP>
            </ListItem>
            <ListItem>
              <ListItemP>
                Zwiększyć lub zmniejszyć odstęp miedzy wybranymi kształtownikami np. górą lub dołem projektu tak aby
                wykorzystać wszystkie pozostałe milimetry,
              </ListItemP>
            </ListItem>
            <ListItem>
              <ListItemP>
                Zwiększyć lub zmniejszyć wysokość projektu tak aby zagospodarować pozostałe milimetry w projekcie.
              </ListItemP>
            </ListItem>
          </List>
        </React.Fragment>
      ),
    };
  }

  if (project && diff && diff < 0) {
    return {
      title: <span>Nie możesz przejść dalej ponieważ projekt jest zbyt duży o <b>{Math.abs(diff)} mm</b>.</span>,
      message: (
        <React.Fragment>
          <ListSpan>Spróbuj:</ListSpan>
          <List>
            <ListItem>
              <ListItemP>
                Dodać kolejne kształtowniki lub wymień dodane na szersze aby zapełnić pozostałe milimetry,
              </ListItemP>
            </ListItem>
            <ListItem>
              <ListItemP>
                Zwiększyć lub zmniejszyć odstęp miedzy wybranymi kształtownikami np. górą lub dołem projektu tak aby
                zmienjszyć projekt,
              </ListItemP>
            </ListItem>
            <ListItem>
              <ListItemP>
                Zwiększyć lub zmniejszyć wysokość projektu tak aby dostosować wysokość w projekcie.
              </ListItemP>
            </ListItem>
          </List>
        </React.Fragment>
      ),
    };
  }

  return null;
};

const ProjectSelect = (
  {
    project,
    addProject,
    setExampleModel,
    setMenu,
  }
) => {
  const dispatch = useDispatch();
  const formik = useFormikContext();
  const projects = useSelector(state => state.app.projects);
  const numberOfProjectsPerType = useSelector(state => state.app.numberOfProjectsPerType);
  const exampleModel = useSelector(state => state.app.exampleModel);
  const menu = useSelector(state => state.app.menu);

  const onChange = (e) => {
    const alert = createAlertInCaseOfErrorsBeforeChangeProjectType(project);
    if (alert) {
      dispatch({ type: SET_ALERT, alert });

      return;
    }

    const isNewProject = fencesTypesOnlyKeys.includes(e.target.value);
    if (!isNewProject) {
      dispatch({ type: SET_CURRENT_PROJECT_ID, projectId: e.target.value });

      return;
    }

    const fenceType = e.target.value;

    const allProjectFenceTypesExists = areAllProjectFenceTypesExists(numberOfProjectsPerType);
    const projectsInThisTypeNotExist = findProjectsInType(projects, fenceType).length === 0;
    if (exampleModel.used && projectsInThisTypeNotExist && !allProjectFenceTypesExists) {
      setExampleModel({ ...exampleModel, showAlert: true, nextFenceType: fenceType });

      return;
    }

    updateMinHeight(fenceType);

    const newProject = projectBase(fenceType, numberOfProjectsPerType);
    addProject(newProject);
    formik.setFieldValue('projects', [
      ...formik.values.projects,
      newProject
    ]);
  };

  const updateMinHeight = (fenceType) => {
    let newMenu;
    switch (fenceType) {
      case FENCE_TYPE_GATE_SELF_SUPPORTING:
        newMenu = { ...menu, height: { ...menu.height, min: 262 } };
        break;
      case FENCE_TYPE_GATE_TILTING:
      case FENCE_TYPE_GATEWAY:
        newMenu = { ...menu, height: { ...menu.height, min: 180 } };
        break;
      default:
        break;
    }
    if (newMenu) {
      setMenu(newMenu);
    }
  };

  const defaultValue = project && project.id ? project.id : '';

  const Options = ({type}) => projects.map((project) => {
    return project.type === type ?
      <option key={project.id} value={project.id}>{fencesTypesInPolish[type]} {project.indexForHuman} (H:{project.heightExpected})</option> : null;
  });

  const label = project ? 'Projektujesz teraz' : 'Stwórz nowy projekt';

  return (
    <div className="form-group">
      <Label htmlFor="fenceTypeSelect">{label}</Label>
      <Select className={`form-control`} id={'fenceTypeSelect'} value={defaultValue} onChange={onChange}>
        <option value={''} disabled>{label}</option>
        <optgroup label="Przęsła">
          <Options type={FENCE_TYPE_SPAN}/>
          <option value={FENCE_TYPE_SPAN}>Nowe przęsło</option>
        </optgroup>
        <optgroup label="Furtki">
          <Options type={FENCE_TYPE_GATEWAY}/>
          <option value={FENCE_TYPE_GATEWAY}>Nowa furtka</option>
        </optgroup>
        <optgroup label="Bramy uchylne">
          <Options type={FENCE_TYPE_GATE_TILTING}/>
          <option value={FENCE_TYPE_GATE_TILTING}>Nowa brama uchylna</option>
        </optgroup>
        <optgroup label="Bramy samonośne">
          <Options type={FENCE_TYPE_GATE_SELF_SUPPORTING}/>
          <option value={FENCE_TYPE_GATE_SELF_SUPPORTING}>Nowa brama samonośna</option>
        </optgroup>
      </Select>
    </div>
  );
};

const Select = styled.select`
  border-radius: 33px;
  background: #000000;
  color: #ffffff;
  padding: 0.25rem 1.5rem;
  border: none;
  
  &:focus {
    background: #000000;
    color: #ffffff;
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  & > optgroup {
    background-color: #080808;
    color: #ffffff;
    font-style: normal;
  }
`;

const Label = styled.label`
  color: #c0c2c2;
  font-size: 0.8rem;
`;

const ListSpan = styled.span`
  padding: 2rem 1rem 0 1rem;
`;

const List = styled.ul`
  padding: 0.5rem 2rem;
`;

const ListItem = styled.li`
  color: #ff0000;
`;

const ListItemP = styled.p`
  color: #000000;
  font-size: 1rem;
  text-align: justify;
`;

export default ProjectSelect;
