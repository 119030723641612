export const FENCE_TYPE_GATE_SELF_SUPPORTING = 'gateSelfSupporting';
export const FENCE_TYPE_GATE_TILTING = 'gateTilting';
export const FENCE_TYPE_GATEWAY = 'gateway';
export const FENCE_TYPE_SPAN = 'span';

export const fencesTypesInPolish = {
  [FENCE_TYPE_GATE_SELF_SUPPORTING]: 'Brama samonośna',
  [FENCE_TYPE_GATE_TILTING]: 'Brama uchylna',
  [FENCE_TYPE_GATEWAY]: 'Furtka',
  [FENCE_TYPE_SPAN]: 'Przęsło',
};

// TODO use only fencesTypes below
export const fencesTypesOnlyKeys = [
  FENCE_TYPE_GATE_SELF_SUPPORTING,
  FENCE_TYPE_GATE_TILTING,
  FENCE_TYPE_GATEWAY,
  FENCE_TYPE_SPAN,
];

export const fencesTypes = [
  {
    name: FENCE_TYPE_GATE_SELF_SUPPORTING,
    label: 'Brama samonośna',
  },
  {
    name: FENCE_TYPE_GATE_TILTING,
    label: 'Brama uchylna',
  },
  {
    name: FENCE_TYPE_GATEWAY,
    label: 'Furtka',
  },
  {
    name: FENCE_TYPE_SPAN,
    label: 'Przęsło',
  },
];

export default fencesTypes;
