import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { CLOSED_MODAL_COPIED_PROJECT } from '../../actions';

const ModalHeader = styled.div`
  display: flex;
  padding: 2rem 2rem 1rem 2rem;
`;

const Body = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: lighter;
`;

const SmallerTitle = styled.h3`
  font-weight: lighter;
  margin-top: 2.5rem;
`;

const Btn = styled.button`
  border-radius: 22px;
  padding: 0.5rem 2rem;
  margin-right: 1rem;
`;

const CopiedProjectModal = ({ project }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(true);
  const shouldModalShow = project.projectWasCopied && project.showModalCopiedProject;
  const showInfoTooHigh = project.copiedProjectTooHigh;

  useEffect(() => {setShowModal(shouldModalShow)}, [project, shouldModalShow]);

  const close = () => {
    dispatch({ type: CLOSED_MODAL_COPIED_PROJECT, project, showModalCopiedProject: false })
  };

  if (showModal) {
    return (
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <ModalHeader>
              <Title>UWAGA</Title>
            </ModalHeader>
            <Body className="modal-body">
              <b>Pamiętaj, że:</b>
              <ul>
                <li>
                  podczas kopiowania wzoru powielają się domyślne odstępy między profilami oraz odstępy, które zostały
                  {' '}
                  zmienione ręcznie
                </li>
                <li>
                  jeśli po skopiowaniu wzoru zmienisz domyśny odstęp między profilami, to zmianie nie będą podlegały
                  {' '}
                  te odstępy, które zmieniałeś ręcznie
                </li>
                <li>
                  w każdej chwili możesz ręcznie skorygować każdy z odstępów w Twoim projekcie.
                </li>
              </ul>
            {showInfoTooHigh && (
              <>
                  <SmallerTitle>JESZCZE COŚ</SmallerTitle>
                  Skopiowany projekt jest wyższy niż aktualne parametry nowego projektu. Możesz teraz:
                  <ul>
                    <li>zwiększyć wysokość obecnego projektu</li>
                    <li>zmniejszyć odstępy w całym projekcie lub wybranych fragmentach</li>
                    <li>usunąć profile</li>
                  </ul>
              </>
            )}
            </Body>
            <div className={"modal-footer"}>
              <Btn
                className="btn btn-success"
                type="button"
                onClick={close}
              >
                <span><b>Rozumiem</b></span>
              </Btn>
            </div>
          </div>
        </div>
      </div>
    );
  } return null;
};

export default CopiedProjectModal;
