import React from 'react';
import {useSelector} from 'react-redux';
import {FENCE_TYPE_GATE_SELF_SUPPORTING, FENCE_TYPE_GATE_TILTING, FENCE_TYPE_GATEWAY, FENCE_TYPE_SPAN} from 'fenceTypesConstants';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  margin-left: 450px;
  padding: 2rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.openTooltip && 'background: #000000; opacity: 0;'};
`;

const ProjectName = styled.h1`
  font-weight: lighter;
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-right: 2rem;
`;

const ProjectInfo = styled.p`
  text-align: right;
  font-size: 0.8rem;
  margin-right: 1.5rem;
`;

const Img = styled.img`
  width: 40px;
  height: auto;
`;

export const getNumberOfSpanWithLabel = (quantity) => {
  if (quantity === 1) {
    return `${quantity} przęsło`;
  } else if (quantity > 1 && quantity < 5) {
    return `${quantity} przęsła`;
  } else if (quantity === 0 || quantity >= 5) {
    return `${quantity} przęseł`;
  }
};

export const getNumberOfGatewayWithLabel = (quantity) => {
  if (quantity === 1) {
    return `${quantity} furtkę`;
  } else if (quantity > 1 && quantity < 5) {
    return `${quantity} furtki`;
  } else if (quantity === 0 || quantity >= 5) {
    return `${quantity} furtek`;
  }
};

export const getNumberOfGateTiltingWithLabel = (quantity) => {
  if (quantity === 1) {
    return `${quantity} bramę uchylną`;
  } else if (quantity > 1 && quantity < 5) {
    return `${quantity} bramy uchylne`;
  } else if (quantity === 0 || quantity >= 5) {
    return `${quantity} bram uchylnych`;
  }
};

export const getNumberOfGateSelfSupportingWithLabel = (quantity) => {
  if (quantity === 1) {
    return `${quantity} bramę samonośną`;
  } else if (quantity > 1 && quantity < 5) {
    return `${quantity} bramy samonośne`;
  } else if (quantity === 0 || quantity >= 5) {
    return `${quantity} bram samonośnych`;
  }
};

const Header = ({project, openTooltip}) => {
  const numberOfProjectsPerType = useSelector(state => state.app.numberOfProjectsPerType);

  const getProjectName = () => {
    if (!project) {
      return `Twój nowy projekt`;
    } else if (project.type === FENCE_TYPE_SPAN) {
      return `Twoje przęsło (${project.indexForHuman})`
    } else if (project.type === FENCE_TYPE_GATEWAY) {
      return `Twoja furtka (${project.indexForHuman})`
    }  else if (project.type === FENCE_TYPE_GATE_TILTING) {
      return `Twoja brama uchylna (${project.indexForHuman})`
    }  else if (project.type === FENCE_TYPE_GATE_SELF_SUPPORTING) {
      return `Twoja brama samonośna (${project.indexForHuman})`
    }
  };

  return (
    <HeaderContainer id={'header'} openTooltip={openTooltip}>
      <div>
      <ProjectName>{getProjectName()}</ProjectName>
      </div>
      <HeaderRight>
      <div>
        <ProjectInfo>Zaprojektowałeś już:<br/> {getNumberOfSpanWithLabel(numberOfProjectsPerType[FENCE_TYPE_SPAN])}, {getNumberOfGatewayWithLabel(numberOfProjectsPerType[FENCE_TYPE_GATEWAY])}, {getNumberOfGateTiltingWithLabel(numberOfProjectsPerType[FENCE_TYPE_GATE_TILTING])}, {getNumberOfGateSelfSupportingWithLabel(numberOfProjectsPerType[FENCE_TYPE_GATE_SELF_SUPPORTING])}.</ProjectInfo>
      </div>
        <Img src={'img/icon/icon.svg'} alt={''}/>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
