import {connect} from 'react-redux';
import ProjectBoard from '../components/ProjectBoard';
import {setAlert, updateProject} from '../actions';

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  updateProject: (project) => dispatch(updateProject(project)),
  setAlert: (alert) => dispatch(setAlert(alert)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectBoard);
