export const FourthASpan = {
  additionalElementTop: null,
  additionalElementBottom: null,
  rods: [
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 0,
    }
  ],
};

export const FourthAGateway = {
  additionalElementTop: {
    space: 12,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    }
  ],
};

export const FourthAGateTilting = {
  additionalElementTop: {
    space: 12,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 80
  },
  rods: [
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 12,
    },
    {
      size: 40,
      space: 12,
    },
    {
      size: 20,
      space: 12,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    },
    {
      size: 80,
      space: 13,
    },
    {
      size: 40,
      space: 13,
    },
    {
      size: 20,
      space: 13,
    }
  ],
};

export const FourthAGateSelfSupporting = {
  additionalElementTop: {
    space: 12,
    size: 80
  },
  additionalElementBottom: {
    space: null,
    size: 162
  },
  rods: [
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 17,
    },
    {
      size: 40,
      space: 17,
    },
    {
      size: 20,
      space: 17,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
    {
      size: 80,
      space: 18,
    },
    {
      size: 40,
      space: 18,
    },
    {
      size: 20,
      space: 18,
    },
  ],
};
