import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DISAPPEARING_TIMEOUT } from '../App';
import { calcProjectHeight } from './RodSpace';
import styled from 'styled-components';

export const RodSizeContainer = styled.div`
  background: #808080;
`;

export const RodSizeSelect = styled.select`
  border-radius: 50px;
  background: #ffffff;
  color: #000000;
  
  &:focus{
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

const RodSize = ({ id, size, project, updateProject, setAlert, disabled }) => {
  const [saved, setSaved] = useState(null);

  const alertTitle = (msg) => (`${msg} Pozostało Ci ${project.heightExpected - project.height} mm.`);

  const sizeChange = (e) => {
    const size = parseInt(e.target.value);

    const newProject = { ...project };

    newProject.rods = project.rods.map((rod) => rod.id === id ? {
      ...rod,
      size: size,
    } : rod);

    const heightAfterUpdate = calcProjectHeight(newProject);

    if (heightAfterUpdate > project.heightExpected) {
      setAlert({ title: alertTitle(`Nie możesz zmienić rozmiaru.`) });
    } else {
      updateProject(newProject);
      setSaved('Zapisano.');
      setTimeout(() => setSaved(null), DISAPPEARING_TIMEOUT);
    }
  };

  return (
    <RodSizeContainer className={'form-group rodSizeContainer'}>
      <RodSizeSelect className={'form-control'} id={`size__${id}`} value={size} onChange={sizeChange} disabled={disabled}>
        <option disabled>Kształtownik:</option>
        <option value={20}>Kształtownik 20 mm</option>
        <option value={40}>Kształtownik 40 mm</option>
        <option value={80}>Kształtownik 80 mm</option>
        <option value={100}>Kształtownik 100 mm</option>
      </RodSizeSelect>
      <small className="form-text">{saved}</small>
    </RodSizeContainer>
  );
};

RodSize.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  updateProject: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default RodSize;
