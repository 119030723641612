import React from 'react';
import PropTypes from 'prop-types';
import ProjectTable from './ProjectTable';
import ProjectVisualisation from './ProjectVisualisation';

const Project = ({project, pdfOptions}) => {
  return (
    <>
      <ProjectTable project={project} pdfOptions={pdfOptions}/>
      <ProjectVisualisation project={project}/>
    </>
  );
};

Project.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Project;
